<script setup lang="ts">
import InnSection from "@/components/general/Section.vue";
import InnInput from "@/components/ds/input/Input.vue";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import InnForm from "@/components/ds/form/Form.vue";
import { PropType, defineProps, computed } from "vue";
import { Client } from "@/types";
import commonTools from "@/tools/common-tools";
import { clientToSlug } from "@/tools/client";
import { useUiStore } from "@/stores/ui";

const props = defineProps({
    client: {
        type: Object as PropType<Client>,
        required: true,
    },
});

const uiStore = useUiStore();

const copySlug = () => {
    commonTools.clipboard(slug.value);
    uiStore.message("Gekopieerd...");
};

const slug = computed(() => {
    if (props.client) {
        return clientToSlug(props.client, -1);
    } else {
        return "";
    }
});

const config = {
    sections: [
        {
            items: [
                {
                    type: "text",
                    key: "vat",
                    label: "BTW nummer",
                },
            ],
        },
        {
            items: [
                {
                    type: "number",
                    key: "rate",
                    label: "Tarief",
                },
            ],
        },
        {
            items: [
                {
                    type: "text",
                    key: "contactPerson",
                    label: "Contactpersoon",
                },
                {
                    type: "text",
                    key: "street",
                    label: "Straat",
                },
                {
                    type: "text",
                    key: "zipcode",
                    label: "Postcode",
                },
                {
                    type: "text",
                    key: "city",
                    label: "Plaats",
                },
            ],
        },
        {
            items: [
                {
                    type: "text",
                    key: "telephone",
                    label: "Telefoon",
                },
                {
                    type: "text",
                    key: "email",
                    label: "Email",
                },
                {
                    type: "text",
                    key: "web",
                    label: "www",
                },
            ],
        },
    ],
};
</script>

<template>
    <div class="ClientDetails">
        <inn-section>
            <div class="ClientDetails__name">
                <inn-input v-model="client.name" placeholder="Naam" />

                <icon-button @click="copySlug" icon-id="paperclip">
                    Kopieer slug: {{ slug }}
                </icon-button>
            </div>
        </inn-section>

        <inn-form :config="config" :value="client" />
    </div>
</template>

<style lang="scss" scoped>
.ClientDetails {
    &__name {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
</style>
