<script setup lang="ts">
import InnButton from "@/components/ds/button/Button.vue";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { computed, defineProps, PropType } from "vue";
import { Document } from "@/types";
import { documentToPrefix, documentToSlug } from "@/tools/document";
import api from "@/api";
import { useDocumentsStore } from "@/stores/documents";
import { useUiStore } from "@/stores/ui";
import { useRouter } from "vue-router";
import { useProjectStore } from "@/stores/projects";
import { projectToSlug } from "@/tools/projects";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const documentsStore = useDocumentsStore();
const projectsStore = useProjectStore();
const uiStore = useUiStore();
const router = useRouter();

const prefix = computed(() => documentToPrefix(props.document));

const slug = computed(() => documentToSlug(props.document));

const goBackToProject = () => {
    const project = projectsStore.getItemById(props.document.projectId);
    if (project) {
        const slug = projectToSlug(project, -1);
        router.push({
            name: "Project",
            params: { slug: slug },
        });
    }
};

const deleteDocument = () => {
    if (props.document) {
        api.delete(props.document, "documents").then(() => {
            documentsStore.delete(props.document);
            goBackToProject();
            uiStore.message(prefix.value + " verwijderd...");
        });
    }
};
</script>

<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <icon-button
                v-bind="activatorProps"
                icon-id="trash"
                variant="danger"
            >
                Verwijder {{ prefix }}
            </icon-button>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card style="padding: 12px">
                <v-card-text>
                    Wil je {{ prefix }} {{ slug }} verwijderen?
                </v-card-text>

                <v-card-actions>
                    <inn-button
                        @click="
                            () => {
                                isActive.value = false;
                                deleteDocument();
                            }
                        "
                    >
                        Verwijderen
                    </inn-button>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<style lang="scss" scoped>
.DocumentDelete {
}
</style>
