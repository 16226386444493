const clipboard = (value: string) => {
    let success = true;
    const range = document.createRange();
    const tmpElem = document.createElement("div");
    tmpElem.style.position = "absolute";
    tmpElem.style.left = "-1000px";
    tmpElem.style.top = "-1000px";
    tmpElem.textContent = value;

    document.body.appendChild(tmpElem);
    // Select temp element.
    range.selectNodeContents(tmpElem);
    const selection = window.getSelection();
    if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        try {
            success = document.execCommand("copy", false);
        } catch (e) {
            console.log(e);
            success = false;
        }
        if (success) {
            tmpElem.remove();
        }
    }
};

const currencyFormat = (v: number) => {
    let value = String(v);
    while (/(\d+)(\d{3})/.test(value.toString())) {
        value = value.toString().replace(/(\d+)(\d{3})/, "$1" + "." + "$2");
    }
    return value;
};

const digitize = (x: number) => {
    if (x < 100) {
        if (x < 10) {
            return "00" + x;
        } else {
            return "0" + x;
        }
    } else {
        return x;
    }
};

export default {
    clipboard,
    currencyFormat,
    digitize,
};
