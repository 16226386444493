<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { documentToSlug } from "@/tools/document";
import { Document } from "@/types";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const documentTitle = computed(() => {
    const prefix = props.document.doctype;
    const slug = documentToSlug(props.document);
    return `${prefix}-${slug}.pdf`;
});

const open = () => {
    const fileLocation =
        "https://drive.google.com/file/d/" +
        props.document?.google_drive_id +
        "/view?usp=sharing";
    window.open(fileLocation);
};
</script>

<template>
    <div @click="open" class="MailAttachement">
        <div class="MailAttachement__icon">
            <i class="fas fa-paperclip"></i>
        </div>
        <div class="MailAttachement__title">
            {{ documentTitle }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.MailAttachement {
    cursor: pointer;
    background: #eee;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}
</style>
