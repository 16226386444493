import { defineStore } from "pinia";
import { Project } from "@/types";
import { useClientStore } from "@/stores/clients";

interface ProjectState {
    list: Project[];
    search: string;
    client_id: number | "*";
    employee_id: number | "*";
    year: number | "*";
    liveProjects: boolean;
}

export const useProjectStore = defineStore("projects", {
    state: () => {
        return {
            list: [],
            search: "",
            client_id: "*",
            employee_id: "*",
            year: new Date().getFullYear(),
            liveProjects: true,
        } as ProjectState;
    },
    getters: {
        sorted(state: ProjectState) {
            return state.list
                .filter((project: Project) => {
                    const matchesSearch = () =>
                        state.search === "" ||
                        project.projectName
                            .toLowerCase()
                            .includes(state.search.toLowerCase());

                    const matchesYear = () =>
                        state.year === "*" || project.year === state.year;

                    const matchesClient = () =>
                        state.client_id === "*" ||
                        project.clientId === state.client_id;

                    const matchesEmployee = () =>
                        state.employee_id === "*" ||
                        project.user_id === state.employee_id;

                    const matchesStatus = () => {
                        const liveStatuses = [0, 1, 2];
                        return (
                            !state.liveProjects ||
                            liveStatuses.includes(project.projectStatus)
                        );
                    };

                    return (
                        matchesSearch() &&
                        matchesYear() &&
                        matchesClient() &&
                        matchesStatus() &&
                        matchesEmployee()
                    );
                })
                .sort((a, b) => a.projectStatus - b.projectStatus);
        },
    },
    actions: {
        delete(item: Project) {
            const index = this.list.indexOf(item);
            this.list.splice(index, 1);
        },
        create(item: Project) {
            this.list.push(item);
        },
        getNew() {
            const today = new Date();
            const year = today.getFullYear();
            const project: Project = {
                id: 0,
                projectName: "",
                projectStatus: 0,
                user_id: 0,
                clientId: 0,
                hours: 0,
                rate: 80,
                discount: 0,
                currency: "EUR",
                year,
                finished: false,
                comments: [],
            };

            return project;
        },
        getClientByProjectId(project_id: number) {
            const project = this.list.find(
                (project) => project.id === project_id
            );
            if (!project) {
                return null;
            } else {
                const clientsStore = useClientStore();
                return clientsStore.list.find(
                    (client) => client.id === project.clientId
                );
            }
        },
        getItemById(id: number) {
            return this.list.find((item) => item.id === id);
        },
        getTotalsForStatus(status_id: number, year: number) {
            return this.list
                .filter(
                    (project) =>
                        (project.projectStatus === status_id ||
                            status_id === -1) &&
                        project.year === year
                )
                .reduce((acc, project) => {
                    acc += project.hours * project.rate - project.discount;
                    return acc;
                }, 0);
        },
    },
});
