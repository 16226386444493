import { defineStore } from "pinia";
import { Company } from "@/types";

interface CompanyState {
    company: Company | null;
}

export const useCompanyStore = defineStore("company", {
    state: () => {
        return {
            company: null,
        } as CompanyState;
    },
    getters: {},
    actions: {},
});
