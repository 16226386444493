<script setup lang="ts">
import { PropType, computed } from "vue";
import Logo from "@/components/general/Logo.vue";
import DocumentSlug from "./DocumentSlug.vue";
import PaidStatus from "./PaidStatus.vue";
import { Document } from "@/types";
import { defineProps } from "vue";
import { useDocumentsStore } from "@/stores/documents";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const documentsStore = useDocumentsStore();

const clientName = computed(() => {
    const client = documentsStore.getClientForDocument(props.document);
    return client ? client.name : "";
});
</script>

<template>
    <div class="DocumentCard">
        <div class="DocumentCard__header">
            <logo :size="3" />

            <div class="DocumentCard__slug">
                <document-slug :document="document" />
            </div>
        </div>

        <div class="DocumentCard__client">
            {{ clientName }}
        </div>

        <div class="DocumentCard__title">
            {{ document.title }}
        </div>

        <div class="DocumentCard__paid-status">
            <paid-status :document="document" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.DocumentCard {
    background: #fff;
    width: 125px;
    height: 177px;
    padding: 12px 6px 10px 6px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    --color-shadow: #999;
    box-shadow: var(--color-shadow) 2px 6px 12px -3px;
    border: 1px solid transparent;
    overflow: hidden;
    font-size: 11px;
    line-height: 1.2;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 12px;
    }

    &__slug {
        font-weight: 700;
        background: #eee;
        padding: 5px 3px;
        line-height: 1;
        font-size: 13px;
    }

    &__client {
        font-weight: 700;
    }

    &__title {
        overflow-wrap: break-word;
    }

    &__paid-status {
        position: absolute;
        right: 12px;
        bottom: 12px;
    }

    &:hover {
        border: 1px solid var(--active-color);
    }
}
</style>
