<script setup lang="ts">
import { useDocumentsStore } from "@/stores/documents";
import InnFilter from "@/components/general/Filter.vue";
import InnSelect from "@/components/ds/select/Select.vue";
import InnInput from "@/components/ds/input/Input.vue";
import { getYearList } from "@/tools/date";
import { useCompanyStore } from "@/stores/company";

const documentsStore = useDocumentsStore();
const companyStore = useCompanyStore();

const doctypes = [
    {
        key: "invoice",
        label: "Factuur",
    },
    {
        key: "quotation",
        label: "Offerte",
    },
    {
        key: "*",
        label: "Alle",
    },
];

const paidStatuses = [
    {
        key: true,
        label: "Betaald",
    },
    {
        key: false,
        label: "Niet betaald",
    },
    {
        key: "*",
        label: "Alle",
    },
];

const years = [
    "*",
    ...getYearList(
        companyStore.company ? companyStore.company.startingYear : 2020
    ),
];
</script>

<template>
    <inn-filter>
        <inn-input v-model="documentsStore.search" placeholder="Zoeken..." />

        <inn-select
            :items="doctypes"
            v-model="documentsStore.doctype"
            item-title="label"
            item-value="key"
        />

        <inn-select :items="years" v-model="documentsStore.year" />

        <inn-select
            :items="paidStatuses"
            v-model="documentsStore.paid"
            item-title="label"
            item-value="key"
        />
    </inn-filter>
</template>
