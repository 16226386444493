<script setup lang="ts">
import { useRoute } from "vue-router";
import { computed } from "vue";
import BreadcrumbLink from "@/components/general/breadcrumbs/BreadcrumbLink.vue";
import { Client, Document, Project } from "@/types";
import {
    getProjectForDocument,
    projectToSlug,
    projectToTo,
    getProjectForMail,
} from "@/tools/projects";
import { useProjectStore } from "@/stores/projects";
import { useClientStore } from "@/stores/clients";
import {
    clientToSlug,
    clientToTo,
    getClientForDocument,
    getClientForProject,
} from "@/tools/client";
import {
    documentToSlug,
    documentToTitle,
    documentToTo,
} from "@/tools/document";
import { useDocumentsStore } from "@/stores/documents";

const route = useRoute();
const projectsStore = useProjectStore();
const clientStore = useClientStore();
const documentsStore = useDocumentsStore();

const page = computed(() => {
    return route.name;
});

const client = computed(() => {
    switch (page.value) {
        case "Client":
            const slug = route.params.slug as string;
            const clientId = Number(slug.split("-")[0]);
            return clientStore.list.find(
                (client: Client) => client.id == clientId
            );
        case "Project":
            const project = projectsStore.list.find((project: Project) => {
                const projectSlug = projectToSlug(project, -1);
                return projectSlug === route.params.slug;
            });
            if (project) {
                return getClientForProject(project);
            }
            return null;
        case "Document":
            const docSlug = String(route.params.slug);
            const docPrefix = String(route.params.prefix);
            const document = documentsStore.list.find(
                (document: Document) =>
                    documentToSlug(document) === docSlug &&
                    document.doctype === docPrefix
            );
            if (document) {
                return getClientForDocument(document);
            }
            return null;
        case "Mail":
            const mail = documentsStore.getMailById(Number(route.params.id));
            if (mail) {
                const document = documentsStore.getItemById(mail.documentId);
                if (document) {
                    return getClientForDocument(document);
                }
            }
            return null;
        default:
            return null;
    }
});

const project = computed(() => {
    switch (page.value) {
        case "Project":
            return projectsStore.list.find((project: Project) => {
                const projectSlug = projectToSlug(project, -1);
                return projectSlug === route.params.slug;
            });
        case "Document":
            const docSlug = String(route.params.slug);
            const docPrefix = String(route.params.prefix);
            const document = documentsStore.list.find(
                (document: Document) =>
                    documentToSlug(document) === docSlug &&
                    document.doctype === docPrefix
            );
            if (document) {
                return getProjectForDocument(document);
            }
            return null;
        case "Mail":
            const mail = documentsStore.getMailById(Number(route.params.id));
            if (mail) {
                return getProjectForMail(mail);
            }
            return null;
        default:
            return null;
    }
});

const document = computed(() => {
    switch (page.value) {
        case "Document":
            const slug = String(route.params.slug);
            const prefix = String(route.params.prefix);
            return documentsStore.list.find(
                (document: Document) =>
                    documentToSlug(document) === slug &&
                    document.doctype === prefix
            );
        case "Mail":
            const mail = documentsStore.getMailById(Number(route.params.id));
            if (mail) {
                return documentsStore.getItemById(mail.documentId);
            }
            return null;
        default:
            return null;
    }
});

const mail = computed(() => {
    if (page.value === "Mail") {
        return documentsStore.getMailById(Number(route.params.id));
    }
    return null;
});

const links = computed(() => {
    const c = {
        to:
            client.value && page.value !== "Client"
                ? clientToTo(client.value)
                : null,
        title: client.value ? clientToSlug(client.value, -1) : null,
    };
    const p = {
        to:
            project.value && page.value !== "Project"
                ? projectToTo(project.value)
                : null,
        title: project.value ? projectToSlug(project.value, -1) : null,
    };
    const d = {
        to:
            document.value && page.value !== "Document"
                ? documentToTo(document.value)
                : null,
        title: document.value ? documentToTitle(document.value) : null,
    };
    const m = {
        to: null,
        title: mail.value ? mail.value.subject : null,
    };
    switch (page.value) {
        case "Client":
            return [c];
        case "Project":
            return [c, p];
        case "Document":
            return [c, p, d];
        case "Mail":
            return [c, p, d, m];
        default:
            return [];
    }
});
</script>

<template>
    <ul class="Breadcrumbs">
        <breadcrumb-link
            v-for="(link, index) in links"
            :key="index"
            :link="link"
        />
    </ul>
</template>

<style lang="scss" scoped>
.Breadcrumbs {
    padding-left: 16px;
    display: flex;
    gap: 14px;
    align-items: center;
    list-style: none;
}
</style>
