<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Employee } from "@/types/";

defineProps({
    employee: {
        type: Object as PropType<Employee>,
        required: true,
    },
});

const isCurrent = computed(() => {
    return false;
    // return (
    //     this.$store.state.clients.current &&
    //     this.client.id === this.$store.state.clients.current.id
    // );
});
</script>

<template>
    <div :class="{ 'EmployeeCard--current': isCurrent }" class="EmployeeCard">
        {{ employee.name }}
    </div>
</template>

<style lang="scss">
.EmployeeCard {
    padding: 8px;
    background: var(--color-grey-5);
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 0;

    &--current,
    &:hover {
        background: var(--active-color);
    }
}
</style>
