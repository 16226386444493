<script setup lang="ts">
import InnButton from "@/components/ds/button/Button.vue";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import api from "@/api";
import { PropType, defineProps } from "vue";
import { Project } from "@/types";
import { useProjectStore } from "@/stores/projects";
import { useRouter } from "vue-router";
import { useUiStore } from "@/stores/ui";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
});

const router = useRouter();
const uiStore = useUiStore();

const projectsStore = useProjectStore();

const deleteProject = () => {
    if (props.project) {
        api.delete(props.project, "projects").then(() => {
            if (props.project) {
                projectsStore.delete(props.project);
            }
            router.push({ name: "Projects" });
            uiStore.message("Project verwijderd...");
        });
    }
};
</script>

<template>
    <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
            <icon-button
                v-bind="activatorProps"
                icon-id="trash"
                variant="danger"
            >
                Verwijder project
            </icon-button>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card style="padding: 12px">
                <v-card-text>
                    Wil je {{ project.projectName }} verwijderen?
                </v-card-text>

                <v-card-actions>
                    <inn-button
                        @click="
                            () => {
                                isActive.value = false;
                                deleteProject();
                            }
                        "
                    >
                        Verwijderen
                    </inn-button>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>
