import { defineStore } from "pinia";
import { Employee } from "@/types";

interface EmployeeState {
    list: Employee[];
    search: string;
}

export const useEmployeeStore = defineStore("employees", {
    state: () => {
        return {
            list: [],
            search: "",
        } as EmployeeState;
    },
    getters: {
        sorted(state: EmployeeState) {
            return state.list.filter((employee: Employee) => {
                return (
                    state.search.length === 0 ||
                    employee.name
                        .toLowerCase()
                        .includes(state.search.toLowerCase())
                );
            });
        },
    },
    actions: {
        delete(item: Employee) {
            const index = this.list.indexOf(item);
            this.list.splice(index, 1);
        },
        create(item: Employee) {
            this.list.push(item);
        },
        getNew() {
            const employee: Employee = {
                id: 0,
                name: "",
                email: "",
                mailFooter: "",
            };
            return employee;
        },
        getItemById(id: number) {
            return this.list.find((item) => item.id === id);
        },
    },
});
