<script setup lang="ts">
import { defineProps, PropType } from "vue";
import { Document } from "@/types";
import IconButton from "@/components/ds/icon-button/IconButton.vue";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const open = () => {
    const fileLocation =
        "https://drive.google.com/file/d/" +
        props.document?.google_drive_id +
        "/view?usp=sharing";
    window.open(fileLocation);
};
</script>

<template>
    <icon-button @click="open" icon-id="arrow-right">
        Open PDF vanaf Google Drive
    </icon-button>
</template>
