<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useClientStore } from "@/stores/clients";
import { Client } from "@/types";
import NavigateBack from "@/components/ds/navigate-back/NavigateBack.vue";
import { useDebounceFn } from "@vueuse/core";
import api from "@/api";
import Panel from "@/components/general/Panel.vue";
import ClientDetails from "@/components/pages/client/ClientDetails.vue";
import { useUiStore } from "@/stores/ui";
import ClientSecondary from "@/components/pages/client/ClientSecondary.vue";
import ClientTools from "@/components/pages/client/ClientTools.vue";
import Cols from "@/components/general/Cols.vue";
import ItemPage from "@/components/general/ItemPage.vue";

const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const clientsStore = useClientStore();

const client = ref<Client | null>(null);
const watchActive = ref(false);

const atBack = () => {
    router.push({ name: "Clients" });
};

const debouncedFn = useDebounceFn(() => {
    if (client.value) {
        api.update(client.value, "clients").then(() => {
            uiStore.message("Wijzigingen opgeslagen...");
        });
    }
}, 1000);

watch(
    () => client.value,
    () => {
        if (watchActive.value) {
            debouncedFn();
        }
    },
    { deep: true }
);

onMounted(() => {
    const slug = route.params.slug as string;
    const clientId = Number(slug.split("-")[0]);
    const c = clientsStore.list.find((client: Client) => client.id == clientId);
    if (c) {
        client.value = c;
        setTimeout(() => {
            watchActive.value = true;
        });
    } else {
        router.push({ name: "Clients" });
    }
});
</script>

<template>
    <item-page v-if="client">
        <template #header>
            <navigate-back @click="atBack"> Terug naar klanten </navigate-back>
        </template>

        <template #cols>
            <panel title="Primair">
                <client-details :client="client" />
            </panel>

            <panel title="Secundair">
                <client-secondary :client="client" />
            </panel>

            <client-tools :client="client" />
        </template>
    </item-page>
</template>
