import { Language } from "@/types";

const languages: Language[] = [
    {
        id: 1,
        title: "Nederlands",
        isoCode: "nl",
    },
    {
        id: 2,
        title: "Engels",
        isoCode: "en",
    },
];

export default languages;
