<script setup lang="ts">
import { computed } from "vue";
import { useDocumentsStore } from "@/stores/documents";
import { Document } from "@/types";
import { documentToSlug } from "@/tools/document";
import { useRouter } from "vue-router";
import DocumentCard from "@/components/pages/documents/card/DocumentCard.vue";
import DocumentsFilter from "@/components/pages/documents/DocumentsFilter.vue";

const router = useRouter();
const documentsStore = useDocumentsStore();

const list = computed(() => {
    return documentsStore.sorted;
});

const selectDocument = (document: Document) => {
    const slug = documentToSlug(document);
    const prefix = document.doctype;
    router.push({ name: "Document", params: { slug, prefix } });
};
</script>

<template>
    <div class="Documents">
        <div class="Documents__filter">
            <documents-filter />
        </div>

        <div class="Documents__list">
            <ul>
                <li v-for="document in list" :key="document.id">
                    <document-card
                        :document="document"
                        @click="selectDocument(document)"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.Documents {
    height: 100%;
    padding: 20px 0;

    &__filter {
        height: 80px;
    }

    &__list {
        height: calc(100% - 80px);
        overflow: auto;

        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }
    }
}
</style>
