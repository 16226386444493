<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import FormInput from "@/components/ds/form/items/FormInput.vue";
import FormCheckbox from "@/components/ds/form/items/FormCheckbox.vue";
import FormSelect from "@/components/ds/form/items/FormSelect.vue";
import FormTextarea from "@/components/ds/form/items/FormTextarea.vue";
import FormColor from "@/components/ds/form/items/FormColor.vue";
import FormImage from "@/components/ds/form/items/FormImage.vue";
import FormHtml from "@/components/ds/form/items/FormHtml.vue";
import { FormValue } from "@/types";

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    value: {
        type: Object as PropType<FormValue>,
        required: true,
    },
});

const components = {
    FormInput,
    FormCheckbox,
    FormSelect,
    FormTextarea,
    FormColor,
    FormImage,
    FormHtml,
};

const component = computed(() => {
    switch (props.item.type) {
        case "checkbox":
            return components.FormCheckbox;
        case "select":
            return components.FormSelect;
        case "text":
        case "number":
            return components.FormInput;
        case "textarea":
            return components.FormTextarea;
        case "color":
            return components.FormColor;
        case "image":
            return components.FormImage;
        case "html":
            return components.FormHtml;
        default:
            return "FormInput";
    }
});
</script>

<template>
    <div class="FormItem">
        <div class="FormItem__item">
            <component :is="component" :item="item" :value="value" />
        </div>

        <label>
            {{ item.label }}
        </label>
    </div>
</template>

<style lang="scss" scoped>
.FormItem {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    min-height: 42px;

    &__item {
        flex-grow: 1;
        height: 100%;
        max-width: 300px;
    }

    label {
        padding: 13px 0;
        width: 140px;
        line-height: 1.15;
        font-size: 13px;
    }
}
</style>
