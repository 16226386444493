<script setup lang="ts">
import { ref } from "vue";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { useRouter } from "vue-router";
import { useEmployeeStore } from "@/stores/employees";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import InnInput from "@/components/ds/input/Input.vue";

const employeesStore = useEmployeeStore();
const uiStore = useUiStore();

const router = useRouter();
const name = ref("");
const email = ref("");

const createEmployee = () => {
    const employee = employeesStore.getNew();
    employee.name = name.value;
    employee.email = email.value;
    api.create(employee, "users").then((response) => {
        employee.id = response.id;
        employeesStore.create(employee);
        uiStore.message("Werknemer aangemaakt..");
        router.push({ name: "Employee", params: { id: employee.id } });
    });
};
</script>

<template>
    <div class="EmployeeCreate">
        <div class="EmployeeCreate__props">
            <inn-input v-model="name" placeholder="Naam van werknemer..." />
            <inn-input v-model="email" placeholder="Email..." />
        </div>

        <div class="EmployeeCreate__tools">
            <icon-button
                :disabled="name.length === 0"
                icon-id="plus"
                @click="createEmployee"
            >
                Werknemer aanmaken
            </icon-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.EmployeeCreate {
    background: #fff;

    &__props {
        padding: 20px;
        border-bottom: 1px solid var(--color-grey-7);
        display: flex;
        flex-direction: column;
        gap: 8px;

        input {
            background: #eee;
            border: 1px solid #ddd;
            width: 100%;
        }
    }

    &__tools {
        padding: 20px;
    }
}
</style>
