<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Mail } from "@/types";
import NavigateBack from "@/components/ds/navigate-back/NavigateBack.vue";
import { useDebounceFn } from "@vueuse/core";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import { useEmployeeStore } from "@/stores/employees";
import { useDocumentsStore } from "@/stores/documents";
import { documentToSlug } from "@/tools/document";
import MailSend from "@/components/pages/mail/MailSend.vue";
import MailAttachement from "@/components/pages/mail/MailAttachement.vue";
import MailContent from "@/components/pages/mail/MailContent.vue";
import MailSubject from "@/components/pages/mail/MailSubject.vue";
import MailReceiver from "@/components/pages/mail/MailReceiver.vue";
import MailSender from "@/components/pages/mail/MailSender.vue";

const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const employeesStore = useEmployeeStore();
const documentsStore = useDocumentsStore();

const mail = ref<Mail | null>(null);
const watchActive = ref(false);

const atBack = () => {
    if (document.value) {
        const slug = documentToSlug(document.value);
        const prefix = document.value.doctype;
        router.push({ name: "Document", params: { prefix, slug } });
    }
};

const debouncedFn = useDebounceFn(() => {
    if (mail.value) {
        api.update(mail.value, "mails").then(() => {
            uiStore.message("Wijzigingen opgeslagen...");
        });
    }
}, 1000);

const document = computed(() => {
    return documentsStore.getItemById(mail.value?.documentId || 0);
});

const employee = computed(() => {
    return employeesStore.getItemById(mail.value?.user_id || 0);
});

const canEdit = computed(() => {
    return (
        mail.value &&
        !mail.value.sent &&
        uiStore.user?.id === mail.value.user_id
    );
});

watch(
    () => mail.value,
    () => {
        if (watchActive.value) {
            debouncedFn();
        }
    },
    { deep: true }
);

onMounted(() => {
    const id = Number(route.params.id);
    const m = documentsStore.getMailById(id);
    if (m) {
        mail.value = m;
        setTimeout(() => {
            watchActive.value = true;
        });
    }
});
</script>

<template>
    <div v-if="mail" class="Mail">
        <navigate-back @click="atBack"> Terug naar factuur </navigate-back>

        <div class="Mail__main">
            <div class="Mail__status" v-if="mail.sent">
                Deze mail is verzonden.
            </div>

            <div class="Mail__status" v-if="!canEdit && !mail.sent">
                Je kunt deze mail niet bewerken
            </div>

            <div class="Mail__container">
                <div class="Mail__addresses Mail__section">
                    <div class="Mail__info-row">
                        <mail-sender :mail="mail" :employee="employee" />
                    </div>

                    <div class="Mail__info-row">
                        <mail-receiver :mail="mail" :can-edit="canEdit" />
                    </div>
                </div>

                <div class="Mail__subject Mail__section">
                    <div class="Mail__info-row">
                        <mail-subject :mail="mail" :can-edit="canEdit" />
                    </div>
                </div>

                <div class="Mail__content">
                    <mail-content
                        :mail="mail"
                        :employee="employee"
                        :can-edit="canEdit"
                    />
                </div>

                <div v-if="document" class="Mail__attachements">
                    <mail-attachement :document="document" />
                </div>
            </div>

            <div v-if="!mail.sent && canEdit" class="Mail__footer">
                <mail-send :mail="mail" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.Mail {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px 0 200px 0;

    &__status {
        background: var(--warning-color);
        color: #fff;
        padding: 10px;
    }

    &__main {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    &__container {
        background: #fff;
        padding: 20px;
        box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12),
            0px 5px 5px -3px rgba(0, 0, 0, 0.2);
    }

    &__section {
        border-bottom: 1px solid var(--color-grey-7);
        padding: 6px 0;
    }

    &__content {
        padding: 6px 0;
    }

    &__attachements {
        padding: 20px 0;
        display: flex;
    }
}

:deep(.Mail__label) {
    width: 100px;
    color: #aaa;
}

:deep(.Mail__info-row) {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 6px;
}

:deep(.Mail__row-content) {
    width: calc(100% - 100px);

    input {
        width: 100%;
    }
}

:deep(input) {
    border: 1px solid transparent;

    &:focus {
        border: 1px solid var(--active-color);
    }
}
</style>
