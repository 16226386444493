<script>
export default {
    name: "inn-cover",
    setup(props, { emit }) {
        return {
            close: () => emit("close"),
        };
    },
};
</script>

<template>
    <Teleport to="body">
        <div class="Cover-container">
            <div @click="close" class="Cover" />
            <div class="Cover__content">
                <slot />
            </div>
        </div>
    </Teleport>
</template>

<style scoped>
.Cover-container {
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.Cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.Cover__content {
    position: relative;
    width: 100%;
    height: 100%;
}
</style>
