<script setup lang="ts">
import { useProjectStore } from "@/stores/projects";
import { useClientStore } from "@/stores/clients";
import { useEmployeeStore } from "@/stores/employees";
import InnFilter from "@/components/general/Filter.vue";
import InnSelect from "@/components/ds/select/Select.vue";
import InnInput from "@/components/ds/input/Input.vue";
import InnCheckbox from "@/components/ds/checkbox/InnCheckbox.vue";
import { getYearList } from "@/tools/date";
import { useCompanyStore } from "@/stores/company";

const projectsStore = useProjectStore();
const clientsStore = useClientStore();
const employeesStore = useEmployeeStore();
const companyStore = useCompanyStore();

const clients = [
    {
        id: "*",
        name: "Alle",
    },
    ...clientsStore.list,
];

const employees = [
    {
        id: "*",
        name: "Alle",
    },
    ...employeesStore.list,
];

const years = [
    "*",
    ...getYearList(
        companyStore.company ? companyStore.company.startingYear : 2020
    ),
];
</script>

<template>
    <inn-filter>
        <inn-input v-model="projectsStore.search" placeholder="Zoeken..." />

        <inn-select
            :items="clients"
            v-model="projectsStore.client_id"
            item-title="name"
            item-value="id"
        />

        <inn-select
            :items="employees"
            v-model="projectsStore.employee_id"
            item-title="name"
            item-value="id"
        />

        <inn-select :items="years" v-model="projectsStore.year" />

        <inn-checkbox
            v-model="projectsStore.liveProjects"
            label="Live projects"
        />
    </inn-filter>
</template>
