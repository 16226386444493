import { FormConfig } from "@/types";

const clientsFormConfig: FormConfig = {
    sections: [
        {
            items: [
                {
                    type: "text",
                    key: "name",
                    label: "Naam",
                },
                {
                    type: "text",
                    key: "email",
                    label: "Email",
                },
            ],
        },
    ],
};

export default clientsFormConfig;
