<script setup lang="ts">
import Logo from "@/components/general/Logo.vue";
import DocumentInfo from "@/components/pages/document/parts/DocumentInfo.vue";
import { computed, defineProps, PropType } from "vue";
import { Document } from "@/types";
import DocumentClientAddress from "@/components/pages/document/parts/DocumentClientAddress.vue";
import DocumentSubject from "@/components/pages/document/parts/DocumentSubject.vue";
import DocumentCompanyAddress from "@/components/pages/document/parts/DocumentCompanyAddress.vue";
import { useDocumentsStore } from "@/stores/documents";
import { useCompanyStore } from "@/stores/company";
import DocumentLegal from "@/components/pages/document/parts/DocumentLegal.vue";
import DocumentLines from "@/components/pages/document/parts/lines/DocumentLines.vue";
import DocumentLineTools from "@/components/pages/document/parts/DocumentLineTools.vue";
import DocumentTotal from "@/components/pages/document/parts/DocumentTotal.vue";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
    editable: {
        type: Boolean,
        default: true,
    },
});

const documentsStore = useDocumentsStore();
const companyStore = useCompanyStore();

const client = computed(() =>
    documentsStore.getClientForDocument(props.document)
);

const company = computed(() => companyStore.company);
</script>

<template>
    <div class="Paper" :data-concept="!document.locked">
        <div class="Paper__container">
            <div class="Paper__header">
                <div class="irl">
                    <logo :size="12" />
                </div>
                <document-info :document="document" :editable="editable" />
            </div>

            <div class="Paper__addresses">
                <document-client-address
                    v-if="client"
                    :document="document"
                    :client="client"
                    :editable="editable"
                />

                <document-company-address
                    v-if="company && client"
                    :company="company"
                    :document="document"
                    :client="client"
                />
            </div>

            <div class="Paper__subject">
                <document-subject :document="document" :editable="editable" />
            </div>

            <div class="Paper__lines">
                <document-lines
                    :document="document"
                    :lines="document.lines"
                    :editable="editable"
                />

                <div class="Paper__line-tools">
                    <document-line-tools
                        v-if="editable"
                        :document="document"
                        :lines="document.lines"
                    />
                </div>

                <document-total
                    v-if="client && !document.hideTotal"
                    :document="document"
                    :lines="document.lines"
                    :client="client"
                />
            </div>

            <div v-if="company" class="Paper__invoice-text irl">
                <template v-if="document.doctype === 'invoice'">
                    <template v-if="document.english">
                        {{ company.invoiceTextEnglish }}
                    </template>
                    <template v-else>
                        {{ company.invoiceText }}
                    </template>
                </template>

                <template v-else>
                    Op deze offerte zijn bijgeleverde Algemene Voorwaarden van
                    toepassing
                </template>
            </div>

            <div v-if="company" class="Paper__footer-image irl">
                <img :src="company.footerImageUrl" />
            </div>

            <div class="irl">
                <document-legal
                    v-if="company"
                    :company="company"
                    :document="document"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.Paper {
    width: 600px;
    min-height: 849px;
    background: #fff;
    padding: 40px;
    position: relative;
    font-size: 14px;
    border: 1px solid var(--color-grey-7);
    transition: all 0.5s ease;

    .irl {
        opacity: 0.5;
        transition: all 0.5s ease;
    }

    &[data-concept="false"] {
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
        border: 1px solid transparent;

        .irl {
            opacity: 1;
        }
    }

    &__container {
        position: relative;
        height: 100%;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        height: 170px;
        border-bottom: 1px solid var(--color-grey-7);
    }

    &__addresses {
        display: flex;
        justify-content: space-between;
        height: 160px;
        border-bottom: 1px solid var(--color-grey-7);

        > div {
            width: 50%;
        }
    }

    &__subject {
        height: 50px;
        border-bottom: 1px solid var(--color-grey-7);
    }

    &__lines {
        min-height: 200px;
    }

    &__invoice-text {
        border-top: 1px solid var(--color-grey-7);
        text-align: center;
        padding: 12px;
        border-bottom: 1px solid var(--color-grey-7);
        font-size: 11px;
    }

    &__footer-image {
        display: flex;
        justify-content: center;
        margin: 20px 0;

        img {
            height: 90px;
        }
    }

    &____line-tools {
        height: 76px;
    }
}
</style>
