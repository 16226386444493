import axios from "axios";
import { Api, Employee, Headers } from "./types";

const api: Api = {};

axios.defaults.headers.common["content-type"] = "application/json";

const getHeaders = (addToken: boolean) => {
    const headers: Headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    if (addToken) {
        headers.Authorization = "Bearer " + localStorage.token;
    }
    return headers;
};

api.login = (item: unknown) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "login";
        axios({
            method: "post",
            url,
            headers: getHeaders(false),
            data: item,
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.create = (item: unknown, endpoint: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + endpoint + "/create/";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: item,
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.read = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "read/";
        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                const user = {
                    id: 1,
                    email: "jeroen@innouveau.nl",
                    name: "Jeroen Breen",
                } as Employee;
                const data = { user, ...response.data };
                // data.documents[6].mails[0].sent = true;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.update = (item: { id: number }, endpoint: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + endpoint + "/" + item.id + "/update/";
        axios({
            method: "put",
            url,
            headers: getHeaders(true),
            data: item,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.delete = (item: { id: number }, endpoint: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + endpoint + "/" + item.id;
        axios({
            method: "delete",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.createAcumulusClient = (id: number) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "acumulus/client/";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                id,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.createAcumulusInvoice = (id: number) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "acumulus/invoice/";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                id,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.printDocument = (id: number) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "print/";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                id,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.uploadDocument = (document_id: number) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "google/drive/";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                id: document_id,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.mail = (mail_id: number) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "google/mail/";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                id: mail_id,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default api;
