<script setup lang="ts">
import Panel from "@/components/general/Panel.vue";
import InnForm from "@/components/ds/form/Form.vue";
import { useCompanyStore } from "@/stores/company";
import { watch } from "vue";
import { useDebounceFn } from "@vueuse/core";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import ItemPage from "@/components/general/ItemPage.vue";
import CompanyPrimary from "@/components/pages/company/CompanyPrimary.vue";
import CompanySecondary from "@/components/pages/company/CompanySecondary.vue";
import CompanyTertiary from "@/components/pages/company/CompanyTertiary.vue";

const companyStore = useCompanyStore();
const uiStore = useUiStore();

const config = {
    sections: [
        {
            items: [
                {
                    type: "text",
                    key: "name",
                    label: "Naam",
                },
                {
                    type: "checkbox",
                    key: "companySameAsEmployee",
                    label: "Bedrijfsnaam is eigen naam (naam van werknemer wordt dan weggelaten op documenten)",
                },
                {
                    type: "number",
                    key: "startingYear",
                    label: "Startjaar",
                },
                {
                    type: "number",
                    key: "standardRate",
                    label: "Standaard tarief",
                },
            ],
        },
        {
            items: [
                {
                    type: "text",
                    key: "address",
                    label: "Adres",
                },
                {
                    type: "text",
                    key: "addressExtra",
                    label: "Adres extra",
                },
                {
                    type: "text",
                    key: "zipcode",
                    label: "Postcode",
                },
                {
                    type: "text",
                    key: "city",
                    label: "Plaats",
                },
                {
                    type: "text",
                    key: "country",
                    label: "Land",
                },
            ],
        },
        {
            items: [
                {
                    type: "textarea",
                    key: "invoiceText",
                    label: "Factuurtekst",
                },
                {
                    type: "textarea",
                    key: "invoiceTextEnglish",
                    label: "Factuurtekst Engels",
                },
                {
                    type: "color",
                    key: "color1",
                    label: "Kleur 1",
                },
                {
                    type: "color",
                    key: "color2",
                    label: "Kleur 2",
                },
                {
                    type: "image",
                    key: "logoUrl",
                    label: "Logo URL",
                },
                {
                    type: "image",
                    key: "footerImageUrl",
                    label: "Footer URL",
                },
            ],
        },
        {
            items: [
                {
                    type: "number",
                    key: "coc",
                    label: "KvK",
                },
                {
                    type: "text",
                    key: "vat",
                    label: "BTW",
                },
                {
                    type: "text",
                    key: "iban",
                    label: "IBAN",
                },
                {
                    type: "text",
                    key: "bic",
                    label: "BIC",
                },
                {
                    type: "text",
                    key: "bankName",
                    label: "Naam bank",
                },
                {
                    type: "text",
                    key: "bankAddress",
                    label: "Adres bank",
                },
            ],
        },
        {
            items: [
                {
                    type: "checkbox",
                    key: "usesMail",
                    label: "Gebruikt mail",
                },
                {
                    type: "checkbox",
                    key: "usesGoogleDrive",
                    label: "Gebruikt Google Drive",
                },
                {
                    type: "checkbox",
                    key: "usesAcumulus",
                    label: "Gebruikt Acumulus",
                },
            ],
        },
    ],
};

const debouncedFn = useDebounceFn(() => {
    if (companyStore.company) {
        api.update(companyStore.company, "companies").then(() => {
            uiStore.message("Wijzigingen opgeslagen...");
        });
    }
}, 1000);

watch(
    () => companyStore.company,
    () => {
        debouncedFn();
    },
    { deep: true }
);
</script>

<template>
    <item-page v-if="companyStore.company">
        <template #cols>
            <panel title="Details">
                <company-primary :company="companyStore.company" />
            </panel>

            <panel title="Template">
                <company-secondary :company="companyStore.company" />
            </panel>

            <panel title="Plugins">
                <company-tertiary :company="companyStore.company" />
            </panel>
        </template>
    </item-page>
</template>
