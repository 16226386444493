<script setup lang="ts"></script>

<template>
    <div class="ItemPage">
        <slot name="header" />

        <div class="ItemPage__cols">
            <slot name="cols" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ItemPage {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;

    &__cols {
        display: flex;
        gap: 20px;
        align-items: flex-start;

        :deep(> div) {
            width: calc(50% - 20px);
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}
</style>
