<script setup lang="ts">
import InnForm from "@/components/ds/form/Form.vue";
import { PropType } from "vue";
import { Company } from "@/types";
import { defineProps } from "vue";

defineProps({
    company: {
        type: Object as PropType<Company>,
        required: true,
    },
});

const config = {
    sections: [
        {
            items: [
                {
                    type: "textarea",
                    key: "invoiceText",
                    label: "Factuurtekst",
                },
                {
                    type: "textarea",
                    key: "invoiceTextEnglish",
                    label: "Factuurtekst Engels",
                },
                {
                    type: "color",
                    key: "color1",
                    label: "Kleur 1",
                },
                {
                    type: "color",
                    key: "color2",
                    label: "Kleur 2",
                },
                {
                    type: "image",
                    key: "logoUrl",
                    label: "Logo URL",
                },
                {
                    type: "image",
                    key: "footerImageUrl",
                    label: "Footer URL",
                },
            ],
        },
    ],
};
</script>

<template>
    <inn-form :value="company" :config="config" />
</template>
