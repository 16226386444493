<script setup lang="ts">
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { defineProps, PropType } from "vue";
import { Document, Line } from "@/types";
import InnButton from "@/components/ds/button/Button.vue";
import api from "@/api";
import { useUiStore } from "@/stores/ui";

const props = defineProps({
    line: {
        type: Object as PropType<Line>,
        required: true,
    },
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const uiStore = useUiStore();

const deleteLine = () => {
    api.delete(props.line, "documentLines").then(() => {
        const index = props.document.lines.indexOf(props.line);
        props.document.lines.splice(index, 1);
        uiStore.message("Post verwijderd...");
    });
};
</script>

<template>
    <div class="DocumentLineDelete">
        <v-dialog max-width="500">
            <template v-slot:activator="{ props: activatorProps }">
                <icon-button
                    v-bind="activatorProps"
                    icon-id="trash"
                    variant="danger"
                />
            </template>

            <template v-slot:default="{ isActive }">
                <v-card style="padding: 12px">
                    <v-card-text>
                        Wil je deze post
                        <span v-if="line.text.length > 0"
                            >("{{ line.text }}")</span
                        >
                        verwijderen?
                    </v-card-text>

                    <v-card-actions>
                        <inn-button
                            @click="
                                () => {
                                    isActive.value = false;
                                    deleteLine();
                                }
                            "
                        >
                            Verwijderen
                        </inn-button>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<style lang="scss" scoped>
.DocumentLineDelete {
}
</style>
