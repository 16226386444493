<script setup lang="ts">
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
    link: {
        type: Object,
        required: true,
    },
});

const router = useRouter();

const gotoTo = () => {
    console.log(props.link.to);
    if (props.link.to !== null) {
        router.push(props.link.to);
    }
};
</script>

<template>
    <li @click="gotoTo" :data-active="link.to !== null" class="BreadcrumbLink">
        {{ link.title }}
    </li>
</template>

<style lang="scss" scoped>
.BreadcrumbLink {
    padding: 2px;
    position: relative;
    font-size: 10px;

    &:after {
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        content: "›";
        text-decoration: none !important;
    }

    &:last-child {
        &:after {
            display: none;
        }
    }

    &[data-active="true"] {
        cursor: pointer;
        background: #fff;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
