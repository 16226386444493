<script setup lang="ts">
import { defineProps } from "vue";
import { statuses } from "@/tools/status";
import StatusYearTotal from "@/components/pages/projects/year-totals/StatusYearTotal.vue";
import { FakeStatus } from "@/types";

defineProps<{
    year: number;
}>();

const totalStatus: FakeStatus = { id: -1, title: "Totaal" };
</script>

<template>
    <div class="YearTotals">
        <status-year-total
            v-for="(status, index) in statuses"
            :key="index"
            :status="status"
            :year="year"
        />

        <status-year-total
            key="total"
            :status="totalStatus"
            :year="year"
            bold
        />
    </div>
</template>

<style lang="scss" scoped>
.YearTotals {
}
</style>
