<script setup lang="ts">
import { defineProps, defineEmits, ref, computed } from "vue";
import InnInput from "@/components/ds/input/Input.vue";
import InnButton from "@/components/ds/button/Button.vue";
import api from "@/api";

defineProps({
    reset: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits(["success"]);

const loading = ref(false);

const submit = () => {
    loading.value = true;
    api.login(user.value)
        .then((response: { access_token: string }) => {
            failMessage.value = "";
            localStorage.token = response.access_token;
            emit("success");
        })
        .catch((error) => {
            failMessage.value = error.message;
        })
        .finally(() => {
            loading.value = false;
        });
};

const failMessage = ref("");

const user = ref({
    email: "",
    password: "",
});

const isDisabled = computed(() => {
    return (
        user.value.email.length === 0 ||
        user.value.password.length === 0 ||
        loading.value
    );
});
</script>

<template>
    <div class="LoginPanel">
        <div class="LoginPanel__form">
            <inn-input placeholder="Email" v-model="user.email" />
            <inn-input
                type="password"
                placeholder="Password"
                v-model="user.password"
            />

            <inn-button :disabled="isDisabled" @click="submit"
                >Login</inn-button
            >

            <div class="LoginPanel__fail">
                <span v-if="failMessage.length">
                    {{ failMessage }}
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.LoginPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #fff;

    &__form {
        display: flex;
        width: 300px;
        flex-direction: column;
        gap: 20px;
    }

    &__fail {
        color: red;
        height: 20px;
    }
}
</style>
