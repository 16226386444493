<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Document, Mail } from "@/types";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { dateToTimestamp } from "@/tools/date";
import { useDocumentsStore } from "@/stores/documents";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import { useRouter } from "vue-router";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const documentsStore = useDocumentsStore();
const uiStore = useUiStore();
const router = useRouter();

const mail = computed<Mail | undefined>(() => {
    return props.document.mails.length > 0 ? props.document.mails[0] : null;
});

const createMail = () => {
    const client = documentsStore.getClientForDocument(props.document);

    const mail = {
        id: 0,
        subject: "Factuur mbt " + props.document.title,
        content: "",
        user_id: props.document.user_id,
        receiver: "",
        date: dateToTimestamp(new Date()),
        mailType: "invoice",
        documentId: props.document.id,
        sent: false,
    };
    if (client) {
        mail.receiver = client.email;
    }
    if (client) {
        mail.content = "Beste " + client.contactPerson + ",\n";
        mail.content += "\n";
        mail.content +=
            "bijgaand de factuur mbt de werkzaamheden voor " +
            props.document.title +
            ".\n";
    }
    api.create(mail, "mails").then((response) => {
        mail.id = response.id;
        props.document.mails.push(mail);
        uiStore.message("Mail aangemaakt..");
        gotoMail(mail);
    });
};

const gotoMail = (mail: Mail) => {
    router.push({ name: "Mail", params: { id: mail.id } });
};
</script>

<template>
    <div class="DocumentMail">
        <icon-button v-if="mail" @click="gotoMail(mail)" icon-id="arrow-right">
            Naar Mail: "{{ mail.subject }}"
        </icon-button>

        <icon-button v-else icon-id="paper-plane" @click="createMail">
            Factuur mailen
        </icon-button>
    </div>
</template>
