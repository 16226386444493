<script setup lang="ts">
import { ref, onMounted, onErrorCaptured } from "vue";
import LoginPanel from "@/components/general/access-layer/LoginPanel.vue";
import Bootstrap from "@/components/general/access-layer/Bootstrap.vue";
import InnPopup from "@/components/ds/popup/Popup.vue";
import { useRouter } from "vue-router";

const router = useRouter();

const hasAccess = ref(false);
const errorMessage = ref<string>("");
const event = ref(null as PromiseRejectionEvent | null);

const handleError = async (error: any) => {
    console.log(error);
    switch (typeof error) {
        case "object":
            if (
                error.response &&
                error.response.status &&
                error.response.status === 401
            ) {
                localStorage.token = "";
                hasAccess.value = false;
                // refresh to clear all state
                router.go();
            } else {
                if (error.message) {
                    errorMessage.value = error.message;
                } else {
                    errorMessage.value = error;
                }
            }

            break;
        case "string":
        default:
            errorMessage.value = error;
            break;
    }
};

onErrorCaptured((error: Error) => {
    handleError(error);
    return false;
});

const atClose = () => {
    errorMessage.value = "";
};

const atSuccess = () => {
    hasAccess.value = true;
};

window.addEventListener("unhandledrejection", (ev: PromiseRejectionEvent) => {
    event.value = ev;
    console.dir(ev.reason);
    handleError(ev.reason);
    ev.preventDefault();
});

onMounted(async () => {
    if (localStorage.token) {
        atSuccess();
    }
});
</script>

<template>
    <template v-if="hasAccess">
        <bootstrap>
            <slot />
        </bootstrap>
    </template>

    <template v-else>
        <login-panel @success="atSuccess" />
    </template>

    <template v-if="errorMessage.length">
        <inn-popup close-button @close="atClose">
            <template #title>
                <slot name="header"> Oops... er ging iets mis </slot>
            </template>

            <template #body>
                <div v-html="errorMessage" />
            </template>

            <template #footer>
                <slot name="footer" :at-close="atClose" />
            </template>
        </inn-popup>
    </template>
</template>
