<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Client } from "@/types/";
import { clientToSlug } from "@/tools/client";

const props = defineProps({
    client: {
        type: Object as PropType<Client>,
        required: true,
    },
});

const setCurrent = () => {
    // this.$store.commit("clients/setCurrent", this.client);
    // localStorage.currentClient = this.client.id;
};

const isCurrent = computed(() => {
    return false;
    // return (
    //     this.$store.state.clients.current &&
    //     this.client.id === this.$store.state.clients.current.id
    // );
});

const slug = computed(() => clientToSlug(props.client, -1));
</script>

<template>
    <div
        @click="setCurrent()"
        :class="{ 'ClientCard--current': isCurrent }"
        class="ClientCard"
    >
        {{ slug }}
    </div>
</template>

<style lang="scss">
.ClientCard {
    padding: 8px;
    background: var(--color-grey-5);
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 0;

    &--current,
    &:hover {
        background: var(--active-color);
    }
}
</style>
