<script setup lang="ts">
import { PropType, defineProps } from "vue";
import { Employee } from "@/types";
import EmployeeCard from "./EmployeeCard.vue";
import { useRouter } from "vue-router";

defineProps({
    list: {
        type: Array as PropType<Employee[]>,
        required: true,
    },
});

const router = useRouter();

const selectEmployee = (employee: Employee) => {
    router.push({ name: "Employee", params: { id: employee.id } });
};
</script>

<template>
    <ul>
        <li v-for="employee in list" :key="employee.id">
            <employee-card
                :employee="employee"
                @click="selectEmployee(employee)"
            />
        </li>
    </ul>
</template>

<style scoped lang="scss">
ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        &:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}
</style>
