export const dateToTimestamp = (date: Date): string => {
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getYearList = (startYear: number): number[] => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = startYear; i <= currentYear; i++) {
        years.unshift(i);
    }
    return years;
};
