<script setup lang="ts">
import InnForm from "@/components/ds/form/Form.vue";
import { PropType } from "vue";
import { Company } from "@/types";
import { defineProps } from "vue";

defineProps({
    company: {
        type: Object as PropType<Company>,
        required: true,
    },
});

const config = {
    sections: [
        {
            items: [
                {
                    type: "text",
                    key: "name",
                    label: "Naam",
                },
                {
                    type: "checkbox",
                    key: "companySameAsEmployee",
                    label: "Bedrijfsnaam is eigen naam (naam van werknemer wordt dan weggelaten op documenten)",
                },
                {
                    type: "number",
                    key: "startingYear",
                    label: "Startjaar",
                },
                {
                    type: "number",
                    key: "standardRate",
                    label: "Standaard tarief",
                },
            ],
        },
        {
            items: [
                {
                    type: "text",
                    key: "address",
                    label: "Adres",
                },
                // {
                //     type: "text",
                //     key: "addressExtra",
                //     label: "Adres extra",
                // },
                {
                    type: "text",
                    key: "zipcode",
                    label: "Postcode",
                },
                {
                    type: "text",
                    key: "city",
                    label: "Plaats",
                },
                {
                    type: "text",
                    key: "country",
                    label: "Land",
                },
            ],
        },
        {
            items: [
                {
                    type: "number",
                    key: "coc",
                    label: "KvK",
                },
                {
                    type: "text",
                    key: "vat",
                    label: "BTW",
                },
                {
                    type: "text",
                    key: "iban",
                    label: "IBAN",
                },
                {
                    type: "text",
                    key: "bic",
                    label: "BIC",
                },
                {
                    type: "text",
                    key: "bankName",
                    label: "Naam bank",
                },
                {
                    type: "text",
                    key: "bankAddress",
                    label: "Adres bank",
                },
            ],
        },
    ],
};
</script>

<template>
    <inn-form :value="company" :config="config" />
</template>
