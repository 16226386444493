<script setup lang="ts">
import { defineProps, defineOptions, defineModel } from "vue";

defineOptions({
    name: "inn-select",
});

defineProps({
    items: {
        type: Array,
        required: true,
    },
    itemTitle: {
        type: String,
        required: false,
        default: null,
    },
    itemValue: {
        type: String,
        required: false,
        default: null,
    },
    compact: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const model = defineModel();
</script>

<template>
    <div class="Select">
        <v-select
            :data-compact="compact"
            :items="items"
            v-model="model"
            :item-title="itemTitle"
            :item-value="itemValue"
            density="compact"
        />
    </div>
</template>

<style lang="scss" scoped>
.Select {
    min-width: 100px;
}

:deep(.v-field) {
    border-radius: 0 !important;
}

:deep(.v-field__overlay) {
    background: var(--color-grey-5) !important;
    opacity: 1 !important;
    border: 2px solid var(--color-grey-7);
}

:deep(.v-input__details) {
    display: none;
}

[data-compact="true"] :deep(.v-field) {
    height: 28px;
    border: 1px solid var(--color-grey-7);
}

[data-compact="true"] :deep(.v-field__overlay) {
    border: 1px solid var(--color-grey-7);
}

[data-compact="true"] :deep(.v-field__input) {
    padding: 0 8px;
    min-height: 28px;
}
</style>
