<script setup lang="ts">
import { ref } from "vue";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { clientToSlug } from "@/tools/client";
import { useRouter } from "vue-router";
import { useClientStore } from "@/stores/clients";
import api from "@/api";
import InnInput from "@/components/ds/input/Input.vue";
import { useCompanyStore } from "@/stores/company";

const clientsStore = useClientStore();
const router = useRouter();
const name = ref("");
const companyStore = useCompanyStore();

const createClient = () => {
    const client = clientsStore.getNew();
    client.name = name.value;
    api.create(client, "clients").then((response) => {
        client.id = response.id;
        if (companyStore.company) {
            client.rate = companyStore.company.standardRate;
        }

        clientsStore.create(client);
        const slug = clientToSlug(client, -1);
        router.push({ name: "Client", params: { slug } });
    });
};
</script>

<template>
    <div class="ClientCreate">
        <div class="ClientCreate__name">
            <inn-input v-model="name" placeholder="Naam van klant..." />
        </div>

        <div class="ClientCreate__tools">
            <icon-button
                :disabled="name.length === 0"
                icon-id="plus"
                @click="createClient"
            >
                Klant aanmaken
            </icon-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ClientCreate {
    background: #fff;

    &__name {
        padding: 20px;
        border-bottom: 1px solid var(--color-grey-7);

        input {
            width: 100%;
        }
    }

    &__tools {
        padding: 20px;
    }
}
</style>
