<script setup lang="ts">
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { defineProps, PropType } from "vue";
import { Project } from "@/types";
import { useRouter } from "vue-router";
import { projectToSlug } from "@/tools/projects";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
});

const router = useRouter();

const toProject = () => {
    const slug = projectToSlug(props.project, -1);
    router.push({ name: "Project", params: { slug } });
};
</script>

<template>
    <icon-button icon-id="arrow-right" @click="toProject"
        >Naar project: {{ project.projectName }}</icon-button
    >
</template>
