<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Document } from "@/types";
import InnInput from "@/components/ds/input/Input.vue";
import InnSelect from "@/components/ds/select/Select.vue";
import { documentToPrefix, documentToSlug } from "@/tools/document";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
    editable: {
        type: Boolean,
        default: true,
    },
});

const slug = computed(() => documentToSlug(props.document));

const months = computed(() => {
    const monthsNl = [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
    ];
    const monthsEn = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const m = props.document.english ? monthsEn : monthsNl;
    return m.map((month, index) => ({ id: index + 1, title: month }));
});

const prefix = computed(() => documentToPrefix(props.document));
</script>

<template>
    <div class="DocumentInfo">
        <div class="DocumentInfo__id">
            <div class="DocumentInfo__prefix">
                {{ prefix }}
            </div>

            <div v-if="editable">
                <inn-input v-model="document.year" compact />
                -

                <inn-input
                    v-model="document.nr"
                    compact
                    class="DocumentInfo__nr"
                />
            </div>

            <div v-else>
                {{ slug }}
            </div>
        </div>

        <div class="DocumentInfo__date">
            <template v-if="!editable">
                {{ document.day }} {{ months[document.month - 1].title }}
                {{ document.year }}
            </template>

            <template v-else>
                <inn-input
                    v-model="document.day"
                    compact
                    class="DocumentInfo__day"
                />

                <inn-select
                    :items="months"
                    v-model="document.month"
                    item-title="title"
                    item-value="id"
                    compact
                />

                <inn-input v-model="document.year" compact />
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.DocumentInfo {
    input {
        width: 55px;
        text-align: right;

        &.DocumentInfo__day {
            width: 40px;
        }

        &.DocumentInfo__nr {
            width: 45px;
        }
    }

    &__id {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        margin-bottom: 8px;
    }

    &__prefix {
        font-weight: 700;
    }

    &__date {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
    }
}
</style>
