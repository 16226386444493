<script setup lang="ts">
import { defineProps, PropType, computed } from "vue";
import { Document, Client, Line } from "@/types";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
    client: {
        type: Object as PropType<Client>,
        required: true,
    },
    lines: {
        type: Array as PropType<Line[]>,
        required: true,
    },
});

const vatShifted = computed(() => props.client.eu);

const getTotal = (multiplier: number) => {
    let total = 0;
    for (let documentLine of props.lines) {
        if (documentLine.lineType === "count") {
            total += documentLine.rate * documentLine.hours;
        } else if (documentLine.lineType === "amount") {
            total += Number(documentLine.amount);
        }
    }
    total *= multiplier;
    total = Math.ceil(100 * total) / 100;
    return total;
};
</script>

<template>
    <div class="DocumentTotal">
        <div
            :class="{ 'DocumentTotal__row--big': vatShifted }"
            class="DocumentTotal__row"
        >
            <div class="DocumentTotal-left">
                <div v-if="document.english">Total</div>
                <div v-else>Totaal</div>
            </div>
            <div class="DocumentTotal-right">
                {{ getTotal(1) }}
                {{ document.currency }}
            </div>
        </div>

        <div v-if="vatShifted" class="DocumentTotal__row">
            <div class="DocumentTotal-left">
                <div v-if="document.english">
                    Vat shifted to {{ client.vat }}
                </div>
                <div v-else>BTW verlegd naar {{ client.vat }}</div>
            </div>
            <div class="DocumentTotal-right"></div>
        </div>

        <div v-if="!vatShifted" class="DocumentTotal__row">
            <div class="DocumentTotal-left">
                <span v-if="document.english"> VAT&nbsp; </span>
                <span v-else> BTW&nbsp; </span>
                {{ document.vat }}%
            </div>
            <div class="DocumentTotal-right">
                {{ getTotal(document.vat / 100) }}
                {{ document.currency }}
            </div>
        </div>

        <div
            v-if="!vatShifted"
            class="DocumentTotal__row DocumentTotal__row--big"
        >
            <div class="DocumentTotal-left">
                <span v-if="document.english">
                    <b>Total Amount</b>&nbsp;&nbsp;
                </span>
                <span v-else> <b>Te betalen</b>&nbsp; </span>
            </div>
            <div class="DocumentTotal-right">
                <b>
                    {{ getTotal(1 + document.vat / 100) }}
                    {{ document.currency }}
                </b>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.DocumentTotal {
    padding: 12px;

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--big {
            font-size: 150%;
            font-weight: 700;
        }
    }
}
</style>
