<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useCompanyStore } from "@/stores/company";

defineProps({
    size: {
        type: Number,
        default: 10,
    },
});

const companyStore = useCompanyStore();

const company = computed(() => companyStore.company);
</script>

<template>
    <div class="Logo">
        <img :src="company?.logoUrl" alt="Logo" />
    </div>
</template>

<style lang="scss" scoped>
.Logo {
    --size: v-bind(size);
    display: flex;
    flex-direction: column;
    gap: calc(0.2px * var(--size));

    img {
        height: calc(8px * var(--size));
    }
}
</style>
