<script setup lang="ts">
import { computed } from "vue";
import ProjectCreate from "./ProjectCreate.vue";
import { useProjectStore } from "@/stores/projects";
import ProjectCard from "@/components/pages/projects/card/ProjectCard.vue";
import ProjectFilter from "@/components/pages/projects/ProjectFilter.vue";
import YearTotals from "@/components/pages/projects/year-totals/YearTotals.vue";

const projectsStore = useProjectStore();

const list = computed(() => {
    return projectsStore.sorted;
});
</script>

<template>
    <div class="Projects">
        <div class="Projects__side">
            <project-create />

            <year-totals
                v-if="projectsStore.year !== '*'"
                :year="projectsStore.year"
            />
        </div>

        <div class="Projects__search">
            <project-filter />

            <div class="Projects__list">
                <ul>
                    <li v-for="project in list" :key="project.id">
                        <project-card :project="project" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.Projects {
    display: flex;
    height: 100%;
    gap: 20px;
    padding: 20px 0;

    &__side {
        width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__search {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 20px;
        overflow: hidden;

        .Search {
            flex-shrink: 0;
        }
    }

    &__list {
        flex-grow: 1;
        overflow: auto;
    }
}
</style>
