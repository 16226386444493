import { Status } from "@/types";

export const statuses: Status[] = [
    {
        id: 0,
        title: "Pijplijn",
        color: "#B29E49",
    },
    {
        id: 1,
        title: "Offerte",
        color: "#35C620",
    },
    {
        id: 2,
        title: "Akkoord",
        color: "#2EC3DB",
    },
    {
        id: 3,
        title: "Factuur",
        color: "#CC2F2F",
    },
    {
        id: 4,
        title: "Betaald",
        color: "#ddd",
    },
    {
        id: 5,
        title: "Gearchiveerd",
        color: "#999",
    },
];

export const getStatusById = (id: number) => {
    return statuses.find((status) => status.id === id);
};
