<script>
import InnCover from "@/components/ds/cover/Cover.vue";
import Icon from "@/components/ds/icon/Icon.vue";

export default {
    name: "inn-popup",
    components: { Icon, InnCover },
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        closeButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        centered: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        return {
            close: () => emit("close"),
        };
    },
};
</script>

<template>
    <inn-cover @close="close">
        <div
            class="Popup"
            :id="id"
            :data-centered="centered"
            :data-close-button="closeButton"
        >
            <div class="Popup__title">
                <h3>
                    <slot name="title" />
                </h3>
            </div>

            <div class="Popup__body">
                <slot name="body" />
            </div>

            <div class="Popup__footer">
                <slot name="footer" />
            </div>

            <button
                class="Popup__close"
                v-if="closeButton"
                @click="close"
                aria-label="Sluit popup"
            >
                <icon icon-id="circle-xmark" />
            </button>
        </div>
    </inn-cover>
</template>

<style scoped>
.Popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - var(40px));
    max-width: 400px;
    max-height: calc(100vh - var(40px));
    overflow: auto;
    background: #fff;
    padding: 40px 20px;
    border-radius: 12px;
    box-shadow: 0px 28px 48px rgba(0, 0, 0, 0.04),
        0px 11.6977px 20.0533px rgba(0, 0, 0, 0.0287542),
        0px 6.25417px 10.7214px rgba(0, 0, 0, 0.0238443),
        0px 3.50603px 6.01034px rgba(0, 0, 0, 0.02),
        0px 1.86203px 3.19205px rgba(0, 0, 0, 0.0161557),
        0px 0.774832px 1.32828px rgba(0, 0, 0, 0.0112458);
}

.Popup__title {
    margin-bottom: 30px;
    position: relative;
}

.Popup__close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--active-color);
    font-size: 24px;
}

.Popup__title h3 {
    margin: 0;
    padding-right: 40px;
}

.Popup__body :deep(p:first-child) {
    margin-top: 0;
}

.Popup__body :deep(p:last-child) {
    margin-bottom: 0;
}

.Popup__footer {
    margin-top: 20px;
}

[data-centered="true"] {
    text-align: center;
}

[data-centered="true"][data-close-button="false"] h3 {
    padding-right: 0;
}
</style>
