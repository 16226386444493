<script setup lang="ts">
import { defineModel, defineProps, useId } from "vue";

defineProps<{
    label: string;
}>();

const model = defineModel();
const id = useId();
</script>

<template>
    <div class="Checkbox">
        <v-checkbox v-model="model" :id="id" :label="label" />
    </div>
</template>

<style scoped lang="scss">
.Checkbox {
    display: flex;
    align-items: center;
    height: 40px;
}

:deep(.v-input__details) {
    display: none;
}
</style>
