<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useProjectStore } from "@/stores/projects";
import { Project } from "@/types";
import NavigateBack from "@/components/ds/navigate-back/NavigateBack.vue";
import { useDebounceFn } from "@vueuse/core";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import { projectToSlug } from "@/tools/projects";
import ProjectDelete from "@/components/pages/project/ProjectDelete.vue";
import ProjectDetails from "@/components/pages/project/ProjectDetails.vue";
import Panel from "@/components/general/Panel.vue";
import ProjectDocuments from "@/components/pages/project/ProjectDocuments.vue";
import ProjectComments from "@/components/pages/project/comments/ProjectComments.vue";
import InnSection from "@/components/general/Section.vue";
import ItemPage from "@/components/general/ItemPage.vue";

const route = useRoute();
const router = useRouter();

const projectsStore = useProjectStore();
const uiStore = useUiStore();

const project = ref<Project | null>(null);
const watchActive = ref(false);

const atBack = () => {
    router.push({ name: "Projects" });
};

const debouncedFn = useDebounceFn(() => {
    if (project.value) {
        api.update(project.value, "projects").then(() => {
            uiStore.message("Wijzigingen opgeslagen...");
        });
    }
}, 1000);

watch(
    () => project.value,
    () => {
        if (watchActive.value) {
            debouncedFn();
        }
    },
    { deep: true }
);

onMounted(() => {
    const slug = route.params.slug;
    const p = projectsStore.list.find((project: Project) => {
        const projectSlug = projectToSlug(project, -1);
        return projectSlug === slug;
    });
    if (p) {
        project.value = p;
        setTimeout(() => {
            watchActive.value = true;
        });
    } else {
        router.push({ name: "Projects" });
    }
});
</script>

<template>
    <item-page v-if="project">
        <template #header>
            <navigate-back @click="atBack">
                Terug naar projecten
            </navigate-back>
        </template>

        <template #cols>
            <panel title="Details">
                <project-details :project="project" />
            </panel>

            <div class="Project__documents">
                <panel title="Facturen">
                    <project-documents doctype="invoice" :project="project" />
                </panel>

                <panel title="Offertes">
                    <project-documents doctype="quotation" :project="project" />
                </panel>
            </div>

            <div class="Project__notes">
                <panel title="Notities">
                    <project-comments :project="project" />
                </panel>

                <panel title="Tools">
                    <inn-section>
                        <project-delete :project="project" />
                    </inn-section>
                </panel>
            </div>
        </template>
    </item-page>
</template>
