<script setup lang="ts">
import InnForm from "@/components/ds/form/Form.vue";
import { PropType } from "vue";
import { Company } from "@/types";
import { defineProps } from "vue";

defineProps({
    company: {
        type: Object as PropType<Company>,
        required: true,
    },
});

const config = {
    sections: [
        {
            items: [
                {
                    type: "checkbox",
                    key: "usesMail",
                    label: "Gebruikt mail",
                },
                {
                    type: "checkbox",
                    key: "usesGoogleDrive",
                    label: "Gebruikt Google Drive",
                },
                {
                    type: "checkbox",
                    key: "usesAcumulus",
                    label: "Gebruikt Acumulus",
                },
            ],
        },
    ],
};
</script>

<template>
    <inn-form :value="company" :config="config" />
</template>
