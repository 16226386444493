import { defineStore } from "pinia";
import { Client } from "@/types";

interface ClientsState {
    list: Client[];
    search: string;
}

export const useClientStore = defineStore("clients", {
    state: () => {
        return {
            list: [],
            search: "",
        } as ClientsState;
    },
    getters: {
        sorted(state: ClientsState) {
            return state.list.filter((client: Client) => {
                return (
                    state.search.length === 0 ||
                    client.name
                        .toLowerCase()
                        .includes(state.search.toLowerCase())
                );
            });
        },
    },
    actions: {
        delete(item: Client) {
            const index = this.list.indexOf(item);
            this.list.splice(index, 1);
        },
        create(item: Client) {
            this.list.push(item);
        },
        getNew() {
            const client: Client = {
                id: 0,
                name: "Innouveau",
                contactPerson: "",
                street: "",
                zipcode: "",
                city: "",
                web: "",
                email: "",
                telephone: "",
                rate: 80,
                info: "",
                vat: "",
                international: false,
                language: "nl",
                eu: false,
                acumulus_client_id: 0,
            };
            return client;
        },
        getItemById(client_id: number) {
            return this.list.find((client) => client.id === client_id);
        },
    },
});
