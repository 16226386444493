import { Project, Document, Mail } from "@/types";
import commonTools from "@/tools/common-tools";
import { useClientStore } from "@/stores/clients";
import { useProjectStore } from "@/stores/projects";
import { useDocumentsStore } from "@/stores/documents";

export const projectToSlug = (project: Project, limitStringTo: number) => {
    const clientsStore = useClientStore();
    const formattedName = project.projectName
        .toLowerCase()
        .replace(/\//g, "-")
        .replace(/\s/g, "-")
        .replace(/\./g, "-")
        .replace(/-+/g, "-");
    const client = clientsStore.getItemById(project.clientId);
    let label;
    if (client) {
        label = commonTools.digitize(client.id) + "-" + formattedName;
    } else {
        label = formattedName;
    }

    if (limitStringTo > -1 && label.length > limitStringTo) {
        return label.substr(0, limitStringTo) + "...";
    } else {
        return label;
    }
};

export const projectToTo = (project: Project) => {
    const slug = projectToSlug(project, -1);
    return { name: "Project", params: { slug } };
};

export const getProjectForDocument = (document: Document) => {
    const projectsStore = useProjectStore();
    return projectsStore.getItemById(document.projectId);
};

export const getProjectForMail = (mail: Mail) => {
    const documentsStore = useDocumentsStore();
    const document = documentsStore.getItemById(mail.documentId);
    if (document) {
        return getProjectForDocument(document);
    } else {
        return null;
    }
};
