<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Client, Document, Company } from "@/types";
import InnSelect from "@/components/ds/select/Select.vue";
import { useEmployeeStore } from "@/stores/employees";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
    client: {
        type: Object as PropType<Client>,
        required: true,
    },
    company: {
        type: Object as PropType<Company>,
        required: true,
    },
});

const employeesStore = useEmployeeStore();

const employee = computed(() => {
    return employeesStore.getItemById(props.document.user_id);
});
</script>

<template>
    <div v-if="company" class="DocumentCompanyAddress">
        <div>
            <b>{{ company.name }}</b>
        </div>

        <div v-if="!company.companySameAsEmployee">
            <span v-if="employee">
                {{ employee.name }}
            </span>
        </div>

        <div>
            {{ company.address }}
        </div>

        <div>{{ company.zipcode }} {{ company.city }}</div>

        <div v-if="client.international">
            {{ company.country }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.DocumentCompanyAddress {
    padding: 12px;
}
</style>
