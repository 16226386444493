<script setup lang="ts">
import api from "@/api";
import { computed, defineProps, PropType } from "vue";
import { Client } from "@/types";
import { useUiStore } from "@/stores/ui";
import AdministrationStatus from "@/components/general/administration-status/AdministrationStatus.vue";
import { AdministrationStatus as AdministrationStatusType } from "@/types";

const props = defineProps({
    client: {
        type: Object as PropType<Client>,
        required: true,
    },
});

const uiStore = useUiStore();

const createAcumulusClient = () => {
    api.createAcumulusClient(props.client.id).then((response) => {
        uiStore.message("Klant aangemaakt in acumulus...");
        // wait for the snackbar to be able to show the second message
        setTimeout(() => {
            props.client.acumulus_client_id = response;
        }, 200);
    });
};

const statuses = computed<AdministrationStatusType[]>(() => {
    return [
        {
            label: "Acumulus",
            done: props.client.acumulus_client_id === 0 ? "idle" : "success",
        },
    ];
});
</script>

<template>
    <administration-status
        @action="createAcumulusClient"
        action-label="Maak klant aan in Acumulus"
        done-label="Klant aangemaakt in Acumulus"
        :statuses="statuses"
    />
</template>
