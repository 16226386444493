<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { DocType, Project, Document, Line } from "@/types";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import InnSection from "@/components/general/Section.vue";
import { useDocumentsStore } from "@/stores/documents";
import DocumentCard from "@/components/pages/documents/card/DocumentCard.vue";
import { useRouter } from "vue-router";
import { documentToPrefix, documentToSlug } from "@/tools/document";
import api from "@/api";
import { useClientStore } from "@/stores/clients";
import { useCompanyStore } from "@/stores/company";
import { useUiStore } from "@/stores/ui";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
    doctype: {
        type: String as PropType<DocType>,
        required: true,
    },
});

const router = useRouter();

const documentsStore = useDocumentsStore();
const clientsStore = useClientStore();
const companyStore = useCompanyStore();
const uiStore = useUiStore();

const canICreate = computed(() => {
    return props.project.user_id === uiStore.user?.id;
});

const trDocType = computed(() => {
    return props.doctype === "invoice" ? "Factuur" : "Offerte";
});

const addDocument = () => {
    const client = clientsStore.getItemById(props.project.clientId);
    const document = documentsStore.getNew();
    document.projectId = props.project.id;
    document.doctype = props.doctype;
    document.user_id = props.project.user_id;
    document.title = props.project.projectName;
    if (companyStore.company) {
        document.rate = companyStore.company.standardRate;
    }
    if (client) {
        document.clientName = client.contactPerson;
    }
    create(document);
};

const documents = computed(() => {
    return documentsStore.list.filter(
        (doc) =>
            doc.projectId === props.project.id && doc.doctype === props.doctype
    );
});

const select = (document: Document) => {
    const slug = documentToSlug(document);
    const prefix = document.doctype;
    router.push({
        name: "Document",
        params: { slug, prefix },
    });
};

const cloneLine = (original: Line, document: Document) => {
    const clone: Line = { ...original };
    clone.documentId = document.id;
    api.create(clone, "documentLines").then((response) => {
        clone.id = response.id;
        document.lines.push(clone);
        uiStore.message("Post aangemaakt..");
    });
};

const cloneDocument = async () => {
    const original = documents.value[documents.value.length - 1];
    const clone = documentsStore.getNew();
    clone.projectId = original.projectId;
    clone.doctype = original.doctype;
    clone.user_id = original.user_id;
    clone.title = original.title;
    clone.rate = original.rate;
    clone.clientName = original.clientName;
    // wait for create to know the id
    await create(clone);
    for (const line of original.lines) {
        cloneLine(line, clone);
    }
};

const create = async (document: Document) => {
    return new Promise((resolve, reject) => {
        api.create(document, "documents").then((response) => {
            document.id = response.id;
            document.nr = response.nr;
            documentsStore.create(document);
            const prefix = documentToPrefix(document);
            uiStore.message(prefix + " aangemaakt..");
            resolve(document);
        });
    });
};
</script>

<template>
    <div class="ProjectDocuments">
        <inn-section v-if="documents.length > 0">
            <div class="ProjectDocuments__list">
                <document-card
                    v-for="document in documents"
                    @click="select(document)"
                    :key="document.id"
                    :document="document"
                />
            </div>
        </inn-section>

        <inn-section v-if="canICreate">
            <div class="ProjectDocuments__tools">
                <icon-button icon-id="plus" @click="addDocument">
                    {{ trDocType }} toevoegen
                </icon-button>

                <icon-button
                    v-if="doctype === 'invoice' && documents.length > 0"
                    icon-id="clone"
                    type="r"
                    @click="cloneDocument"
                >
                    Laatste factuur clonen
                </icon-button>
            </div>
        </inn-section>
    </div>
</template>

<style lang="scss" scoped>
.ProjectDocuments {
    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }

    &__tools {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
</style>
