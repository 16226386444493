import { Client, Document, Project } from "@/types";
import commonTools from "@/tools/common-tools";
import { useProjectStore } from "@/stores/projects";
import { useClientStore } from "@/stores/clients";

export const clientToSlug = (client: Client, limitStringTo: number) => {
    const formattedName = client.name
        .toLowerCase()
        .replace(/\//g, "-")
        .replace(/\s/g, "-")
        .replace(/\./g, "-")
        .replace(/-+/g, "-");
    const label = commonTools.digitize(client.id) + "-" + formattedName;

    if (limitStringTo > -1 && label.length > limitStringTo) {
        return label.substr(0, limitStringTo) + "...";
    } else {
        return label;
    }
};

export const clientToTo = (client: Client) => {
    const slug = clientToSlug(client, -1);
    return { name: "Client", params: { slug } };
};

export const getClientForDocument = (document: Document) => {
    const projectsStore = useProjectStore();
    const project = projectsStore.getItemById(document.projectId);
    if (project) {
        return getClientForProject(project);
    }
    return null;
};

export const getClientForProject = (project: Project) => {
    const clientStore = useClientStore();
    return clientStore.getItemById(project.clientId);
};
