<script setup lang="ts">
import { defineProps, defineEmits, PropType, computed } from "vue";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { AdministrationStatus } from "@/types";
import Status from "@/components/general/administration-status/Status.vue";

const props = defineProps({
    actionLabel: {
        type: String,
        required: true,
    },
    doneLabel: {
        type: String,
        required: true,
    },
    statuses: {
        type: Array as PropType<AdministrationStatus[]>,
        required: true,
    },
});

const emit = defineEmits(["action"]);

const overallStatus = computed(() => {
    if (props.statuses.every((status) => status.done === "success")) {
        return "done";
    } else if (props.statuses.every((status) => status.done === "idle")) {
        return "idle";
    } else {
        return "busy";
    }
});

const doAction = () => {
    emit("action");
};
</script>

<template>
    <div class="AdministrationStatus">
        <div class="AdministrationStatus__label">
            <div v-if="overallStatus === 'done'">
                {{ doneLabel }}
            </div>

            <icon-button
                @click="doAction"
                v-if="overallStatus === 'idle'"
                icon-id="share"
            >
                {{ actionLabel }}
            </icon-button>

            <div v-if="overallStatus === 'busy'">...</div>
        </div>

        <div class="AdministrationStatus__statuses">
            <status
                v-for="(status, index) in statuses"
                :status="status"
                :key="index"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.AdministrationStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 16px;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 60px;

    &__label {
        flex-grow: 1;
    }

    &__statuses {
        display: flex;
        gap: 10px;
    }
}
</style>
