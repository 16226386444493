<script setup lang="ts">
const pages = [
    {
        to: "/projects",
        title: "Projecten",
    },
    {
        to: "/clients",
        title: "Klanten",
    },
    {
        to: "/documents",
        title: "Documenten",
    },
    {
        to: "/employees",
        title: "Werknemers",
    },
    {
        to: "/company",
        title: "Bedrijf",
    },
];
</script>

<template>
    <div class="navigation">
        <ul>
            <li v-for="(page, index) in pages" :key="index">
                <router-link :to="page.to">
                    {{ page.title }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<style scoped lang="scss">
.navigation {
    display: flex;
    position: relative;
    align-items: center;
    font-weight: 700;

    ul {
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            list-style: none;
            margin: 0 8px;

            a {
                padding: 4px 8px;
                color: #000 !important;
                text-decoration: none;
                border-bottom: 0.5px solid transparent;

                &:hover,
                &.router-link-active {
                    text-decoration: none;
                    border-bottom: 0.5px solid #000;
                }
            }
        }
    }
}
</style>
