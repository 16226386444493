import { Document } from "@/types";
import commonTools from "@/tools/common-tools";

export const documentToSlug = (document: Document) => {
    return document.year + "-" + commonTools.digitize(document.nr);
};

export const documentToPrefix = (document: Document) => {
    return document.english
        ? document.doctype === "invoice"
            ? "Invoice"
            : "Quote"
        : document.doctype === "invoice"
        ? "Factuur"
        : "Offerte";
};

export const documentToTitle = (document: Document) => {
    return documentToPrefix(document) + "-" + documentToSlug(document);
};

export const documentToTo = (document: Document) => {
    const slug = documentToSlug(document);
    const prefix = document.doctype;
    return { name: "Document", params: { slug, prefix } };
};
