<script setup lang="ts">
import { PropType, defineProps } from "vue";
import { Employee, FormConfig } from "@/types";
import InnForm from "@/components/ds/form/Form.vue";

defineProps({
    employee: {
        type: Object as PropType<Employee>,
        required: true,
    },
});

const config: FormConfig = {
    sections: [
        {
            items: [
                {
                    type: "html",
                    key: "mailFooter",
                    label: "Email footer",
                },
            ],
        },
    ],
};
</script>

<template>
    <inn-form :config="config" :value="employee" />
</template>
