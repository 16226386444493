<script setup lang="ts">
import { defineProps, PropType, computed } from "vue";
import { Client } from "@/types";
import InnForm from "@/components/ds/form/Form.vue";
import languages from "@/data/languages";
import { useCompanyStore } from "@/stores/company";

defineProps({
    client: {
        type: Object as PropType<Client>,
        required: true,
    },
});

const companyStore = useCompanyStore();

const config = computed(() => {
    const acumulsSection = {
        items: [
            {
                type: "number",
                key: "acumulus_client_id",
                label: "Acumulus klantnummer",
            },
        ],
    };
    const c = {
        sections: [
            {
                items: [
                    {
                        type: "checkbox",
                        key: "international",
                        label: "Internationale klant",
                    },
                    {
                        type: "checkbox",
                        key: "eu",
                        label: "Binnen de EU",
                    },
                    {
                        type: "select",
                        key: "language",
                        label: "Taal",
                        options: languages,
                        valueKey: "isoCode",
                        labelKey: "title",
                    },
                ],
            },
        ],
    };
    if (companyStore.company?.usesAcumulus) {
        c.sections.push(acumulsSection);
    }
    return c;
});
</script>

<template>
    <div class="ClientSecondary">
        <inn-form :config="config" :value="client" />
    </div>
</template>

<style lang="scss" scoped>
.ClientSecondary {
}
</style>
