<script setup lang="ts">
import { defineProps, PropType } from "vue";
import { Project } from "@/types";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import StatusTool from "@/components/general/StatusTool.vue";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
    noUpdate: {
        type: Boolean,
        default: false,
    },
});

const uiStore = useUiStore();

const prevStatus = () => {
    props.project.projectStatus -= 1;
    update();
};

const nextStatus = () => {
    props.project.projectStatus += 1;
    update();
};

const update = () => {
    if (!props.noUpdate) {
        api.update(props.project, "projects").then(() => {
            uiStore.message("Wijzigingen opgeslagen...");
        });
    }
};
</script>

<template>
    <div
        class="ProjectCardTools"
        :style="{
            'flex-direction':
                project.projectStatus === 0 ? 'row-reverse' : 'row',
        }"
    >
        <status-tool
            v-show="project.projectStatus > 0"
            @click="prevStatus()"
            direction="up"
        />

        <status-tool
            v-show="project.projectStatus < 5"
            @click="nextStatus()"
            direction="down"
        />
    </div>
</template>

<style lang="scss" scoped>
.ProjectCardTools {
    display: flex;
    align-items: center;
    width: 41px;
    gap: 5px;
    flex-shrink: 0;
}
</style>
