<script setup lang="ts"></script>

<template>
    <div class="Tools">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
.Tools {
    display: flex;
    flex-direction: column;
}
</style>
