<script setup lang="ts">
import { computed } from "vue";
import Search from "@/components/ds/search/Search.vue";
import EmployeeCreate from "./EmployeeCreate.vue";
import { useEmployeeStore } from "@/stores/employees";
import EmployeeList from "@/components/pages/employees/EmployeeList.vue";

const employeesStore = useEmployeeStore();

const list = computed(() => {
    return employeesStore.sorted;
});
</script>

<template>
    <div class="Employees">
        <div class="Employees__create">
            <employee-create />
        </div>

        <div class="Employees__search">
            <search v-model="employeesStore.search" placeholder="Zoeken..." />

            <div class="Employees__list">
                <employee-list :list="list" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.Employees {
    display: flex;
    height: 100%;
    gap: 20px;
    padding: 20px 0;

    &__create {
        width: 400px;
    }

    &__search {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 20px;
        overflow: hidden;

        .Search {
            flex-shrink: 0;
        }
    }

    &__list {
        flex-grow: 1;
        overflow: auto;
    }
}
</style>
