<script setup lang="ts">
import AcumulusClientCreate from "@/components/pages/client/AcumulusClientCreate.vue";
import InnButton from "@/components/ds/button/Button.vue";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { defineProps, PropType } from "vue";
import { Client } from "@/types";
import api from "@/api";
import { useRouter } from "vue-router";
import { useUiStore } from "@/stores/ui";
import { useClientStore } from "@/stores/clients";
import Tools from "@/components/general/tools/Tools.vue";
import Tool from "@/components/general/tools/Tool.vue";
import { useCompanyStore } from "@/stores/company";
import Panel from "@/components/general/Panel.vue";
import ClientProjects from "@/components/pages/client/ClientProjects.vue";
import InnSection from "@/components/general/Section.vue";

const props = defineProps({
    client: {
        type: Object as PropType<Client>,
        required: true,
    },
});

const router = useRouter();
const uiStore = useUiStore();
const clientsStore = useClientStore();
const companyStore = useCompanyStore();

const deleteClient = () => {
    if (props.client) {
        api.delete(props.client, "clients").then(() => {
            if (props.client) {
                clientsStore.delete(props.client);
            }
            router.push({ name: "Clients" });
            uiStore.message("Klant verwijderd...");
        });
    }
};
</script>

<template>
    <div class="ClientTools">
        <tools>
            <tool v-if="companyStore.company?.usesAcumulus">
                <acumulus-client-create :client="client" />
            </tool>
        </tools>

        <panel title="Projecten">
            <inn-section>
                <client-projects :client="client" />
            </inn-section>
        </panel>

        <panel title="Tools">
            <inn-section>
                <v-dialog max-width="500">
                    <template v-slot:activator="{ props: activatorProps }">
                        <icon-button
                            v-bind="activatorProps"
                            icon-id="trash"
                            variant="danger"
                        >
                            Verwijder klant
                        </icon-button>
                    </template>

                    <template v-slot:default="{ isActive }">
                        <v-card style="padding: 12px">
                            <v-card-text>
                                Wil je {{ client.name }} verwijderen?
                            </v-card-text>

                            <v-card-actions>
                                <inn-button
                                    @click="
                                        () => {
                                            isActive.value = false;
                                            deleteClient();
                                        }
                                    "
                                >
                                    Verwijderen
                                </inn-button>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </inn-section>
        </panel>
    </div>
</template>

<style lang="scss" scoped>
.ClientTools {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>
