<script setup lang="ts">
import { defineProps, PropType } from "vue";
import { Mail, Employee } from "@/types";

defineProps({
    mail: {
        type: Object as PropType<Mail>,
        required: true,
    },
    employee: {
        type: Object as PropType<Employee>,
        required: false,
        default: null,
    },
    canEdit: {
        type: Boolean,
        required: true,
    },
});

const formatContent = (content: string) => {
    return content.replace(/\n/g, "<br>");
};
</script>

<template>
    <div class="MailContent">
        <textarea v-if="canEdit" v-model="mail.content" />

        <div v-else v-html="formatContent(mail.content)" />

        <div
            v-if="employee"
            class="member__mail-footer"
            v-html="employee.mailFooter"
        />
    </div>
</template>

<style lang="scss" scoped>
textarea {
    width: calc(100% + 4px);
    height: 150px;
    margin-left: -4px;
    border: 1px solid transparent;

    &:focus {
        border: 1px solid var(--active-color);
    }
}
</style>
