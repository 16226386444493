import { defineStore } from "pinia";
import { Document } from "@/types";
import { useProjectStore } from "@/stores/projects";

interface DocumentState {
    list: Document[];
    search: string;
    doctype: "invoice" | "quotation" | "*";
    year: number | "*";
    paid: true | false | "*";
}

export const useDocumentsStore = defineStore("documents", {
    state: () => {
        return {
            list: [],
            search: "",
            doctype: "invoice",
            year: new Date().getFullYear(),
            paid: "*",
        } as DocumentState;
    },
    getters: {
        sorted(state: DocumentState) {
            return [...state.list].reverse().filter((document: Document) => {
                const matchesSearch = () =>
                    state.search === "" ||
                    document.title
                        .toLowerCase()
                        .includes(state.search.toLowerCase());

                const matchesYear = () =>
                    state.year === "*" || document.year === state.year;

                const matchesPaid = () =>
                    state.paid === "*" || document.paid === state.paid;

                const matchesType = () =>
                    state.doctype === "*" || document.doctype === state.doctype;

                return (
                    matchesSearch() &&
                    matchesYear() &&
                    matchesPaid() &&
                    matchesType()
                );
            });
        },
    },
    actions: {
        delete(item: Document) {
            const index = this.list.indexOf(item);
            this.list.splice(index, 1);
        },
        create(item: Document) {
            this.list.push(item);
        },
        getNew() {
            const today = new Date();
            const year = today.getFullYear();
            const month = today.getMonth() + 1;
            const day = today.getDate();
            const document: Document = {
                id: 0,
                clientName: "",
                projectId: 0,
                doctype: "invoice",
                currency: "EUR",
                english: false,
                hideTotal: false,
                locked: false,
                nr: 0,
                paid: false,
                user_id: 0,
                title: "",
                vat: 21,
                year,
                month,
                day,
                rate: 0,
                acumulus_invoice_id: 0,
                google_drive_id: "",
                lines: [],
                mails: [],
            };
            return document;
        },
        getClientForDocument(document: Document) {
            const projectsStore = useProjectStore();
            return projectsStore.getClientByProjectId(document.projectId);
        },
        getDocumentsForProjectOfType(
            project_id: number,
            doctype: "invoice" | "quotation"
        ) {
            return this.list.filter(
                (document) =>
                    document.projectId === project_id &&
                    document.doctype === doctype
            );
        },
        getItemById(id: number) {
            return this.list.find((item) => item.id === id);
        },
        getMailById(id: number) {
            for (const document of this.list) {
                for (const mail of document.mails) {
                    if (mail.id === id) {
                        return mail;
                    }
                }
            }
            return null;
        },
    },
});
