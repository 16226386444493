import { defineStore } from "pinia";
import { Employee } from "@/types";

interface UiState {
    snackbar: boolean;
    snackbarMessage: string;
    user: null | Employee;
}

export const useUiStore = defineStore("ui", {
    state: () => {
        return {
            snackbar: false,
            snackbarMessage: "",
            user: null,
        } as UiState;
    },
    getters: {},
    actions: {
        message(message: string) {
            this.snackbar = true;
            this.snackbarMessage = message;
        },
    },
});
