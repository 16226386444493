<script setup lang="ts">
import { defineProps, PropType } from "vue";
import { Document } from "@/types";
import PaidStatus from "@/components/pages/documents/card/PaidStatus.vue";
import { documentToSlug } from "@/tools/document";
import { useRouter } from "vue-router";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const router = useRouter();

const selectDocument = () => {
    const slug = documentToSlug(props.document);
    const prefix = props.document.doctype;
    router.push({ name: "Document", params: { slug, prefix } });
};
</script>

<template>
    <div @click="selectDocument" class="ProjectCardDocument">
        <div class="ProjectCardDocument__paid-status">
            <paid-status :document="document" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ProjectCardDocument {
    width: 17px;
    height: 24px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    font-size: 6px;
    padding: 1px;
    text-align: right;
    line-height: 1.2;
    position: relative;

    &:hover {
        border: 1px solid var(--active-color);
    }

    &__paid-status {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -4px);
    }
}
</style>
