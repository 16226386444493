<script setup lang="ts">
import InnForm from "@/components/ds/form/Form.vue";
import { computed, defineProps, PropType } from "vue";
import { Project } from "@/types";
import { projectToSlug } from "@/tools/projects";
import commonTools from "@/tools/common-tools";
import { useClientStore } from "@/stores/clients";
import { useEmployeeStore } from "@/stores/employees";
import InnSection from "@/components/general/Section.vue";
import InnInput from "@/components/ds/input/Input.vue";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { useUiStore } from "@/stores/ui";
import ProjectCalculation from "@/components/pages/project/ProjectCalculation.vue";
import ProjectStatus from "@/components/pages/project/ProjectStatus.vue";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
});

const clientStore = useClientStore();
const employeeStore = useEmployeeStore();
const uiStore = useUiStore();

const config1 = {
    sections: [
        {
            items: [
                {
                    type: "select",
                    key: "clientId",
                    label: "Klant",
                    options: clientStore.list,
                    valueKey: "id",
                    labelKey: "name",
                },
                {
                    type: "select",
                    key: "user_id",
                    label: "Werknemer",
                    options: employeeStore.list,
                    valueKey: "id",
                    labelKey: "name",
                },
                {
                    type: "text",
                    key: "currency",
                    label: "Currency",
                },
                {
                    type: "checkbox",
                    key: "finished",
                    label: "Afgerond",
                },
            ],
        },
    ],
};

const config2 = {
    sections: [
        {
            items: [
                {
                    type: "number",
                    key: "year",
                    label: "Boekhoudjaar",
                },
            ],
        },
    ],
};

const slug = computed(() => {
    return projectToSlug(props.project, -1);
});

const copySlug = () => {
    commonTools.clipboard(slug.value);
    uiStore.message("Gekopieerd...");
};

const archiveProject = () => {
    props.project.projectStatus = 5;
};
</script>

<template>
    <inn-section>
        <div class="ProjectDetails__project-name">
            <inn-input
                v-model="project.projectName"
                placeholder="Projectnaam"
            />

            <icon-button @click="copySlug" icon-id="paperclip">
                Kopieer slug: {{ slug }}
            </icon-button>
        </div>
    </inn-section>

    <inn-section>
        <project-status :project="project" />
    </inn-section>

    <inn-section>
        <icon-button icon-id="archive" @click="archiveProject">
            Project archiveren
        </icon-button>
    </inn-section>

    <inn-form :config="config1" :value="project" />

    <inn-section>
        <project-calculation :project="project" />
    </inn-section>

    <inn-form :config="config2" :value="project" />
</template>

<style scoped lang="scss">
.ProjectDetails {
    &__project-name {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
</style>
