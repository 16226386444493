<script setup lang="ts">
import api from "@/api";
import { useClientStore } from "@/stores/clients";
import { useEmployeeStore } from "@/stores/employees";
import { useDocumentsStore } from "@/stores/documents";
import { useProjectStore } from "@/stores/projects";
import { useCompanyStore } from "@/stores/company";
import { onMounted, ref } from "vue";
import { useUiStore } from "@/stores/ui";

const clientsStore = useClientStore();
const employeesStore = useEmployeeStore();
const documentsStore = useDocumentsStore();
const projectsStore = useProjectStore();
const companyStore = useCompanyStore();
const uiStore = useUiStore();

const loaded = ref(false);

const bootstrap = () => {
    api.read().then((response: any) => {
        console.log(response);
        clientsStore.list = response.clients;
        employeesStore.list = response.users;
        documentsStore.list = response.documents;
        projectsStore.list = response.projects;
        companyStore.company = response.company;
        uiStore.user = response.user;
        loaded.value = true;
    });
};

onMounted(() => {
    bootstrap();
});
</script>

<template>
    <template v-if="loaded">
        <slot />
    </template>

    <template v-else> ... </template>
</template>
