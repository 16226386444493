<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import { Document } from "@/types";
import { useRoute, useRouter } from "vue-router";
import { useDocumentsStore } from "@/stores/documents";
import { documentToSlug } from "@/tools/document";
import Paper from "@/components/pages/document/Paper.vue";
import DocumentTools from "@/components/pages/document/tools/DocumentTools.vue";
import { useDebounceFn } from "@vueuse/core";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import PaidStatus from "@/components/pages/documents/card/PaidStatus.vue";

const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const documentsStore = useDocumentsStore();
const document = ref<Document | null>(null);
const watchActive = ref(false);

const debouncedFn = useDebounceFn(() => {
    if (document.value) {
        api.update(document.value, "documents").then(() => {
            uiStore.message("Wijzigingen opgeslagen...");
        });
    }
}, 1000);

const canIEdit = computed(() => {
    return (
        document.value?.user_id === uiStore.user?.id && !document.value?.locked
    );
});

watch(
    () => document.value,
    () => {
        if (watchActive.value) {
            debouncedFn();
        }
    },
    { deep: true }
);

onMounted(() => {
    const slug = String(route.params.slug);
    const prefix = String(route.params.prefix);
    const d = documentsStore.list.find(
        (document: Document) =>
            documentToSlug(document) === slug && document.doctype === prefix
    );
    if (d) {
        document.value = d;
        setTimeout(() => {
            watchActive.value = true;
        });
    } else {
        router.push({ name: "Documents" });
    }
});
</script>

<template>
    <div v-if="document" class="Document">
        <div class="Document__paper">
            <paper :document="document" :editable="canIEdit" />

            <div class="Document__side">
                <div class="Document__status" v-if="!document.locked">
                    Concept
                </div>

                <paid-status v-else :document="document" />
            </div>
        </div>

        <document-tools :document="document" :editable="canIEdit" />
    </div>
</template>

<style lang="scss" scoped>
.Document {
    display: flex;
    height: 100%;

    &__paper {
        width: calc(100% - 400px);
        flex-shrink: 0;
        height: 100%;
        overflow: auto;
        padding: 20px 20px 200px 20px;
        display: flex;
        gap: 20px;
        align-items: flex-start;
    }

    .PaidStatus {
        margin-top: 70px;
    }

    &__status {
        background: #fff;
        border: 1px solid var(--color-grey-7);
        padding: 10px 16px;
    }

    .DocumentTools {
        width: 400px;
    }
}
</style>
