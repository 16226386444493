<script setup lang="ts">
import { defineProps, PropType } from "vue";
import { Document, LineType, Line } from "@/types";
import DocumentLineTool from "@/components/pages/document/parts/DocumentLineTool.vue";
import api from "@/api";
import { useUiStore } from "@/stores/ui";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
    lines: {
        type: Array as PropType<Line[]>,
        required: true,
    },
});

const uiStore = useUiStore();

const createDocumentLine = (lineType: LineType) => {
    const rate = lineType === "count" ? props.document.rate : 0;
    const line: Line = {
        lineType,
        documentId: props.document.id,
        arrayOrder: props.lines.length,
        rate,
        hours: 0,
        text: "",
        amount: 0,
    };

    api.create(line, "documentLines").then((response) => {
        line.id = response.id;
        props.document.lines.push(line);
        uiStore.message("Post aangemaakt..");
    });
};
</script>

<template>
    <div class="DocumentLineTools">
        <document-line-tool
            @click="createDocumentLine('count')"
            title="rekenmodel toevoegen"
            icon-id="ellipsis-h"
        />

        <document-line-tool
            @click="createDocumentLine('amount')"
            title="vast bedrag toevoegen"
            icon-id="euro-sign"
        />

        <document-line-tool
            @click="createDocumentLine('subtotal')"
            title="subtotal"
            icon-id="calculator"
        />

        <document-line-tool
            @click="createDocumentLine('text')"
            title="beschrijving toevoegen"
            icon-id="align-left"
        />

        <document-line-tool
            @click="createDocumentLine('enter')"
            title="enter toevoegen"
            icon-id="paragraph"
        />
    </div>
</template>

<style lang="scss" scoped>
.DocumentLineTools {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 20px 12px 30px 12px;
}
</style>
