<script setup lang="ts">
import ClientsList from "@/components/pages/clients/ClientsList.vue";
import { computed } from "vue";
import { useClientStore } from "@/stores/clients";
import Search from "@/components/ds/search/Search.vue";
import ClientCreate from "@/components/pages/clients/ClientCreate.vue";

const clientsStore = useClientStore();

const list = computed(() => {
    return clientsStore.sorted;
});
</script>

<template>
    <div class="Clients">
        <div class="Clients__create">
            <client-create />
        </div>

        <div class="Clients__search">
            <search v-model="clientsStore.search" placeholder="Zoeken..." />

            <div class="Clients__list">
                <clients-list :list="list" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.Clients {
    display: flex;
    height: 100%;
    gap: 20px;
    padding: 20px 0;

    &__create {
        width: 400px;
    }

    &__search {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 20px;
        overflow: hidden;

        .Search {
            flex-shrink: 0;
        }
    }

    &__list {
        flex-grow: 1;
        overflow: auto;
    }
}
</style>
