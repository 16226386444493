<script setup lang="ts">
import { ref } from "vue";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import { useProjectStore } from "@/stores/projects";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import { useClientStore } from "@/stores/clients";
import { useEmployeeStore } from "@/stores/employees";
import InnSelect from "@/components/ds/select/Select.vue";
import InnInput from "@/components/ds/input/Input.vue";

const projectsStore = useProjectStore();
const clientsStore = useClientStore();
const employeesStore = useEmployeeStore();
const uiStore = useUiStore();

const name = ref("");
const client_id = ref(null);

const createProject = () => {
    if (name.value.length > 0 && client_id.value) {
        const project = projectsStore.getNew();
        const client = clientsStore.getItemById(client_id.value);
        project.projectName = name.value;
        project.clientId = client_id.value;
        if (client) {
            project.rate = client.rate;
        }
        api.create(project, "projects").then((response) => {
            project.id = response.id;
            project.user_id = response.user_id;
            projectsStore.create(project);
            uiStore.message("Project aangemaakt..");
            //router.push({ name: "Project", params: { id: project.id } });
        });
    }
};
</script>

<template>
    <div class="ProjectCreate">
        <div class="ProjectCreate__properties">
            <inn-input v-model="name" placeholder="Naam van project..." />

            <inn-select
                :items="clientsStore.list"
                v-model="client_id"
                item-title="name"
                item-value="id"
            />
        </div>

        <div class="ProjectCreate__tools">
            <icon-button
                :disabled="name.length === 0 || !client_id"
                icon-id="plus"
                @click="createProject"
            >
                Project aanmaken
            </icon-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ProjectCreate {
    background: #fff;

    &__properties {
        padding: 20px;
        border-bottom: 1px solid var(--color-grey-7);
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__tools {
        padding: 20px;
    }
}
</style>
