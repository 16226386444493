<script setup lang="ts">
import { defineProps, defineOptions, defineModel } from "vue";

defineProps({
    placeholder: {
        type: String,
        required: false,
        default: "Zoeken...",
    },
    compact: {
        type: Boolean,
        required: false,
        default: false,
    },
});

defineOptions({
    name: "inn-input",
});

const model = defineModel();
</script>

<template>
    <input v-model="model" :placeholder="placeholder" :data-compact="compact" />
</template>

<style lang="scss" scoped>
input {
    background: var(--color-grey-5);
    border: 1px solid var(--color-grey-7);
    height: 40px;
    color: var(--color-black-5);
    padding: 0 16px;
    font-size: 16px;
    outline: none;

    &:focus,
    &:focus-visible {
        border: 1px solid var(--active-color) !important;
    }

    &[data-compact="true"] {
        height: 28px;
        padding: 0 8px;
        border: 1px solid var(--color-grey-7);
    }
}
</style>
