<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Client } from "@/types";
import { useProjectStore } from "@/stores/projects";
import ClientButton from "@/components/general/ClientButton.vue";
import ProjectButton from "@/components/general/ProjectButton.vue";

const props = defineProps({
    client: {
        type: Object as PropType<Client>,
        required: true,
    },
});

const projectsStore = useProjectStore();

const projects = computed(() => {
    return projectsStore.list.filter(
        (project) => project.clientId === props.client.id
    );
});
</script>

<template>
    <div class="ClientProjects">
        <project-button
            v-for="(project, index) in projects"
            :key="index"
            :project="project"
        />
    </div>
</template>

<style lang="scss" scoped>
.ClientProjects {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
</style>
