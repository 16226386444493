import Clients from "@/components/pages/clients/index.vue";
import Client from "@/components/pages/client/index.vue";
import Employees from "@/components/pages/employees/index.vue";
import Employee from "@/components/pages/employee/index.vue";
import Documents from "@/components/pages/documents/index.vue";
import Document from "@/components/pages/document/index.vue";
import Project from "@/components/pages/project/index.vue";
import Projects from "@/components/pages/projects/index.vue";
import Company from "@/components/pages/company/index.vue";
import Mail from "@/components/pages/mail/index.vue";
import Login from "@/components/pages/login/index.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
    { path: "/", component: Projects },
    { name: "Project", path: "/projects/:slug", component: Project },
    { name: "Projects", path: "/projects", component: Projects },
    { name: "Clients", path: "/clients", component: Clients },
    { name: "Client", path: "/clients/:slug", component: Client },
    { name: "Employees", path: "/employees", component: Employees },
    { name: "Employee", path: "/employees/:id", component: Employee },
    { name: "Documents", path: "/documents", component: Documents },
    { name: "Document", path: "/document/:prefix/:slug", component: Document },
    { name: "Company", path: "/company", component: Company },
    { name: "Mail", path: "/mail/:id", component: Mail },
    { name: "Login", path: "/login", component: Login },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
