<script setup lang="ts">
import Navigation from "@/components/general/Navigation.vue";
import { useUiStore } from "@/stores/ui";
import Breadcrumbs from "@/components/general/breadcrumbs/Breadcrumbs.vue";
import AccessLayer from "@/components/general/access-layer/AccessLayer.vue";

const uiStore = useUiStore();
</script>

<template>
    <div class="App">
        <access-layer>
            <div class="App__navigation">
                <Navigation />
                <breadcrumbs />
            </div>

            <div class="App__main">
                <router-view />
            </div>
        </access-layer>

        <v-snackbar v-model="uiStore.snackbar" :timeout="2000">
            {{ uiStore.snackbarMessage }}
        </v-snackbar>
    </div>
</template>

<style lang="scss">
* {
    box-sizing: border-box;
}

#webpack-dev-server-client-overlay {
    display: none !important;
}

body {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;

    --green: #eff3ef;
    --todo-color: #dde8dd;
    --active-color: #ffbb00;
    --active-color-dark: #ffaa00;
    --warning-color: red;
    --warning-color-hover: #b21111;

    --color-grey-5: #eee;
    --color-grey-7: #ddd;
    --color-grey-9: #ccc;

    --color-black-3: #666;
    --color-black-5: #333;

    --status-0: #b29e49;
    --status-1: #35c620;
    --status-2: #70d7e5;
    --status-2--ready: #e0f7fa;
    --status-3: #cc2f2f;
    --status-4: #ddd;
    --status-5: #999;
}
</style>

<style scoped lang="scss">
.App {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--green);

    &__navigation {
        height: 90px;
        padding: 16px 24px;
        border-bottom: 1px solid var(--color-grey-7);
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__main {
        height: calc(100% - 90px);
        overflow: auto;
        padding: 0 40px 0 40px;
    }
}
</style>
