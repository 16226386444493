<script setup lang="ts">
import { computed, defineProps, PropType, watch } from "vue";
import { Document, Line } from "@/types";
import DocumentLineAmount from "@/components/pages/document/parts/lines/DocumentLineAmount.vue";
import DocumentLineEnter from "@/components/pages/document/parts/lines/DocumentLineEnter.vue";
import DocumentLineCount from "@/components/pages/document/parts/lines/DocumentLineCount.vue";
import DocumentLineText from "@/components/pages/document/parts/lines/DocumentLineText.vue";
import { useDebounceFn } from "@vueuse/core";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import Icon from "@/components/ds/icon/Icon.vue";
import DocumentLineDelete from "@/components/pages/document/parts/lines/DocumentLineDelete.vue";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
    line: {
        type: Object as PropType<Line>,
        required: true,
    },
    locked: {
        type: Boolean,
        required: true,
    },
    currency: {
        type: String,
        required: true,
    },
});

const uiStore = useUiStore();

const components = {
    DocumentLineAmount,
    DocumentLineEnter,
    DocumentLineCount,
    DocumentLineText,
};

const component = computed(() => {
    switch (props.line.lineType) {
        case "amount":
            return components.DocumentLineAmount;
        case "count":
            return components.DocumentLineCount;
        case "enter":
            return components.DocumentLineEnter;
        case "text":
            return components.DocumentLineText;
        default:
            return DocumentLineEnter;
    }
});

const debouncedFn = useDebounceFn(() => {
    if (props.line) {
        api.update(props.line, "documentLines").then(() => {
            uiStore.message("Wijzigingen opgeslagen...");
        });
    }
}, 1000);

watch(
    () => props.line,
    () => {
        debouncedFn();
    },
    { deep: true }
);
</script>

<template>
    <li class="DocumentLine">
        <component
            :is="component"
            :line="line"
            :locked="locked"
            :currency="currency"
        />
        <div v-if="!locked" class="DocumentLine__handle">
            <icon icon-id="bars" />
        </div>

        <div v-if="!locked" class="DocumentLine__tools">
            <document-line-delete :document="document" :line="line" />
        </div>
    </li>
</template>

<style lang="scss" scoped>
.DocumentLine {
    list-style: none;
    display: flex;
    gap: 4px;
    align-items: center;
    min-height: 28px;
    padding: 2px 0;
    position: relative;

    &__handle {
        width: 28px;
        height: 28px;
        //background-color: #f0f0f0;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: move;
    }

    &__tools {
        display: flex;
        gap: 4px;
        position: absolute;
        left: 100%;
        padding-left: 10px;
        align-items: center;
        justify-content: center;
    }
}

:deep(.size--a) {
    width: calc(52% - 30px);
    flex-grow: 1;
}

:deep(.size--b) {
    width: 12%;
}

:deep(.size--c) {
    width: 24%;
}

:deep(.line--last) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}
</style>
