<script setup lang="ts">
import { defineProps, ref, PropType } from "vue";
import { Project } from "@/types";
import api from "@/api";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import InnInput from "@/components/ds/input/Input.vue";
import { useUiStore } from "@/stores/ui";
import { dateToTimestamp } from "@/tools/date";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
});

const uiStore = useUiStore();

const input = ref("");

const createComment = () => {
    if (input.value.length > 0) {
        const comment = {
            projectId: props.project.id,
            comment: input.value,
        };
        api.create(comment, "comments").then((response) => {
            comment.id = response.id;
            props.project.comments.push(comment);
            uiStore.message("Notitie aangemaakt..");
            input.value = "";
        });
    }
};
</script>

<template>
    <div class="ProjectCommentCreate">
        <inn-input v-model="input" placeholder="Notitie" />

        <icon-button
            icon-id="plus"
            @click="createComment"
            :disabled="input.length === 0"
        >
            Notitie toevoegen
        </icon-button>
    </div>
</template>

<style lang="scss" scoped>
.ProjectCommentCreate {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>
