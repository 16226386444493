<script setup lang="ts">
import { PropType, defineProps } from "vue";
import { Client } from "@/types";
import ClientCard from "@/components/pages/clients/ClientCard.vue";
import { useRouter } from "vue-router";
import { clientToSlug, clientToTo } from "@/tools/client";

defineProps({
    list: {
        type: Array as PropType<Client[]>,
        required: true,
    },
});

const router = useRouter();

const selectClient = (client: Client) => {
    router.push(clientToTo(client));
};
</script>

<template>
    <ul>
        <li v-for="client in list" :key="client.id">
            <client-card :client="client" @click="selectClient(client)" />
        </li>
    </ul>
</template>

<style scoped lang="scss">
ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        &:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}
</style>
