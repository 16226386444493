<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Project } from "@/types";
import { getStatusById } from "@/tools/status";
import ProjectCardTools from "@/components/pages/projects/card/ProjectCardTools.vue";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
});

const status = computed(() => {
    return getStatusById(props.project.projectStatus);
});
</script>

<template>
    <div class="ProjectStatus">
        <div v-if="status" class="ProjectStatus__label">
            <div
                class="ProjectStatus__icon"
                :style="{ '--color': status.color }"
            />
            <div class="ProjectStatus__text">{{ status.title }}</div>
        </div>

        <project-card-tools :project="project" no-update />
    </div>
</template>

<style lang="scss" scoped>
.ProjectStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__label {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__icon {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: var(--color);
        border: 1px solid var(--color-grey-7);
    }
}
</style>
