<script setup lang="ts">
import { defineProps, PropType, computed } from "vue";
import { DocType, Project } from "@/types";
import { useDocumentsStore } from "@/stores/documents";
import ProjectCardDocument from "./ProjectCardDocument.vue";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
    doctype: {
        type: String as PropType<DocType>,
        required: true,
    },
});

const documentsStore = useDocumentsStore();

const documents = computed(() => {
    return documentsStore.getDocumentsForProjectOfType(
        props.project.id,
        props.doctype
    );
});
</script>

<template>
    <project-card-document
        v-for="(document, index) in documents"
        :key="index"
        :document="document"
    />
</template>
