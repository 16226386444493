<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Project, Document } from "@/types";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
    documents: {
        type: Array as PropType<Document[]>,
        required: true,
    },
});

const applySuggestion = () => {
    props.project.rate = suggestion.value.rate;
    props.project.hours = suggestion.value.hours;
};

const suggestion = computed(() => {
    const totalLines = [];
    const suggestion = {
        hours: 0,
        rate: 0,
        discount: 0,
        ableToMakeSuggestion: true,
    };
    for (const invoice of props.documents) {
        const linesForSuggestion: any[] = [];
        for (const line of invoice.lines) {
            if (line.lineType === "count") {
                let corrected = false;
                if (linesForSuggestion.length > 0) {
                    const previousLine =
                        linesForSuggestion[linesForSuggestion.length - 1];
                    if (
                        previousLine.hours === line.hours &&
                        Math.sign(previousLine.rate) !== Math.sign(line.rate)
                    ) {
                        // this is most likely the expenses for the recruiter
                        previousLine.rate += line.rate;
                        corrected = true;
                    }
                }
                if (!corrected) {
                    linesForSuggestion.push({
                        hours: Number(line.hours),
                        rate: Number(line.rate),
                    });
                }
            }
        }
        totalLines.push(...linesForSuggestion);
    }
    if (totalLines.length > 0) {
        suggestion.rate = totalLines[0].rate;
        for (const line of totalLines) {
            suggestion.hours += Number(line.hours);
            if (line.rate !== suggestion.rate) {
                suggestion.ableToMakeSuggestion = false;
            }
        }
    }
    return suggestion;
});
</script>

<template>
    <div @click="applySuggestion" class="SuggestedCalculation">
        Suggested calculation from the invoices: {{ suggestion.hours }} ×
        {{ suggestion.rate }}
    </div>
</template>

<style lang="scss" scoped>
.SuggestedCalculation {
    color: #aaa;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
</style>
