<script setup lang="ts">
import { defineProps, defineModel } from "vue";
import InnInput from "@/components/ds/input/Input.vue";

defineProps({
    placeholder: {
        type: String,
        default: "",
    },
});

const v = defineModel();
</script>

<template>
    <div class="Search">
        <inn-input v-model="v" :placeholder="placeholder" />
    </div>
</template>

<style lang="scss" scoped>
.Search {
    padding: 12px;
    background: var(--color-grey-7);

    input {
        width: 100%;
        background: #eee;
        border: 1px solid #ddd;

        &:focus {
            border: 1px solid var(--active-color);
        }
    }
}
</style>
