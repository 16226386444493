<script setup lang="ts">
import { computed, defineProps } from "vue";
import { FakeStatus, Status } from "@/types";
import { useProjectStore } from "@/stores/projects";
import commonTools from "@/tools/common-tools";

const props = defineProps<{
    status: Status | FakeStatus;
    year: number;
    bold?: boolean;
}>();

const projectsStore = useProjectStore();

const sum = computed(() => {
    const v = projectsStore.getTotalsForStatus(props.status.id, props.year);
    return commonTools.currencyFormat(v) + " EUR";
});
</script>

<template>
    <div class="StatusYearTotal" :data-bold="bold">
        <div>{{ status.title }}</div>
        <div class="StatusYearTotal__line" />
        <div>{{ sum }}</div>
    </div>
</template>

<style lang="scss" scoped>
.StatusYearTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__line {
        flex-grow: 1;
        border-bottom: 1px solid var(--color-grey-7);
        height: 1px;
        margin: 0 16px;
    }

    &[data-bold="true"] {
        font-weight: bold;
    }
}
</style>
