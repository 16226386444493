<script setup lang="ts">
import { defineProps, PropType, ref } from "vue";
import { Document, Line } from "@/types";
import DocumentLine from "@/components/pages/document/parts/lines/DocumentLine.vue";
import { useSortable } from "@vueuse/integrations/useSortable";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
    lines: {
        type: Array as PropType<Line[]>,
        required: true,
    },
    editable: {
        type: Boolean,
        default: true,
    },
});

const el = ref<HTMLElement | null>(null);
useSortable(el, props.lines, {
    handle: ".DocumentLine__handle",
    onEnd: (e: Event) => {
        let arrayOrder = 0;
        setTimeout(() => {
            for (const line of props.lines) {
                line.arrayOrder = arrayOrder;
                arrayOrder++;
            }
        });
    },
});
</script>

<template>
    <ul ref="el">
        <document-line
            v-for="line in lines"
            :key="line.id"
            :document="document"
            :locked="!editable"
            :currency="document.currency"
            :line="line"
        />
    </ul>
</template>

<style lang="scss" scoped>
ul {
    padding: 12px;
}
</style>
