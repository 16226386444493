<script setup lang="ts">
import { defineProps, PropType, ref } from "vue";
import { Mail } from "@/types";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import InnLoadingScreen from "@/components/ds/loading-screen/LoadingScreen.vue";

const props = defineProps({
    mail: {
        type: Object as PropType<Mail>,
        required: true,
    },
});

const uiStore = useUiStore();

const loading = ref(false);

const send = () => {
    loading.value = true;
    api.mail(props.mail.id)
        .then((response) => {
            console.log(response);
            // props.mail.sent = true;
            uiStore.message("Mail verstuurd...");
        })
        .finally(() => {
            loading.value = false;
        });
};
</script>

<template>
    <div @click="send()" class="MailSend">Verzenden</div>

    <inn-loading-screen v-if="loading" />
</template>

<style lang="scss" scoped>
.MailSend {
    cursor: pointer;
    color: #fff;
    border-radius: 25px;
    padding: 12px 24px;
    background: #0f77e6;
    display: inline-block;
    font-size: 16px;

    &:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    }
}
</style>
