<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Document } from "@/types";
import { documentToSlug } from "@/tools/document";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const slug = computed(() => documentToSlug(props.document));
</script>

<template>
    <div class="DocumentSlug" v-html="slug" />
</template>
