<script setup lang="ts">
import AdministrationStatus from "@/components/general/administration-status/AdministrationStatus.vue";
import { computed, defineProps, PropType, watch } from "vue";
import {
    AdministrationStatus as AdministrationStatusType,
    Document,
} from "@/types";
import api from "@/api";
import { useUiStore } from "@/stores/ui";
import { useCompanyStore } from "@/stores/company";
import { useDocumentsStore } from "@/stores/documents";
import AcumulusClientCreate from "@/components/pages/client/AcumulusClientCreate.vue";

const props = defineProps({
    document: {
        type: Object as PropType<Document>,
        required: true,
    },
});

const uiStore = useUiStore();
const companyStore = useCompanyStore();
const documentsStore = useDocumentsStore();

const client = computed(() =>
    documentsStore.getClientForDocument(props.document)
);

const statuses = computed<AdministrationStatusType[]>(() => {
    const acumulus: AdministrationStatusType = {
        label: "Acumulus",
        done: props.document.acumulus_invoice_id === 0 ? "idle" : "success",
    };

    const drive: AdministrationStatusType = {
        label: "Google Drive",
        done: props.document.google_drive_id === "" ? "idle" : "success",
    };
    const c: AdministrationStatusType[] = [];
    if (companyStore.company?.usesAcumulus) {
        c.push(acumulus);
    }
    if (companyStore.company?.usesGoogleDrive) {
        c.push(drive);
    }
    return c;
});

const uploadDocument = (fileLocation: string) => {
    api.uploadDocument(props.document.id).then((response) => {
        console.log(response);
        props.document.google_drive_id = response;
        props.document.locked = true;

        //window.open(fileLocation);
        // todo remove the file from the local folder? That way google drive is ssot

        setTimeout(() => {
            uiStore.message("Factuur geupload naar Google Drive...");
        }, 200);
    });
};

const printDocument = () => {
    api.printDocument(props.document.id).then((response) => {
        const fileLocation = window.config.api + response;
        uploadDocument(fileLocation);
    });
};

const applyDrive = () => {
    return new Promise((resolve, reject) => {
        printDocument();
        resolve(null);
    });
};

const applyAcumulus = () => {
    return new Promise((resolve, reject) => {
        api.createAcumulusInvoice(props.document.id).then((response) => {
            props.document.acumulus_invoice_id = response;

            setTimeout(() => {
                uiStore.message("Factuur aangemaakt in acumulus...");
                resolve(null);
            }, 500);
        });
    });
};

const applyAdministration = async () => {
    if (companyStore.company?.usesAcumulus) {
        await applyAcumulus();
    }
    if (companyStore.company?.usesGoogleDrive) {
        await applyDrive();
    }
};

watch(
    () => client.value,
    () => {
        if (client.value && client.value.acumulus_client_id !== 0) {
            api.update(client.value, "clients").then(() => {
                uiStore.message("Wijzigingen opgeslagen...");
            });
        }
    },
    { deep: true }
);
</script>

<template>
    <div class="DocumentAdministration">
        <administration-status
            v-if="client?.acumulus_client_id !== 0"
            @action="applyAdministration"
            action-label="Administratie document"
            done-label="Administratie afgerond"
            :statuses="statuses"
        />

        <div v-else class="DocumentAdministration__client">
            <acumulus-client-create :client="client" />
            Maak eerst een acumulus id aan voor deze klant
        </div>
    </div>
</template>

<style lang="scss" scoped>
.DocumentAdministration {
    &__client {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
</style>
