<script setup lang="ts">
import { computed, defineProps, PropType } from "vue";
import { Project } from "@/types";
import InnInput from "@/components/ds/input/Input.vue";
import SuggestedCalculation from "@/components/pages/project/SuggestedCalculation.vue";
import { useDocumentsStore } from "@/stores/documents";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
});

const documentsStore = useDocumentsStore();

const result = computed(() => {
    return props.project.hours * props.project.rate - props.project.discount;
});

const documents = computed(() => {
    return documentsStore.list.filter(
        (doc) => doc.projectId === props.project.id && doc.doctype === "invoice"
    );
});
</script>

<template>
    <div class="ProjectCalculation">
        <div class="ProjectCalculation__main">
            <inn-input
                v-model="project.hours"
                label="Uren"
                data-id="hours"
                type="number"
            />
            ×
            <inn-input
                v-model="project.rate"
                label="Tarief"
                data-id="rate"
                type="number"
            />
            -
            <inn-input
                v-model="project.discount"
                label="Korting"
                data-id="discount"
                type="number"
            />
            =
            <div class="ProjectCalculation__result">
                {{ result }} {{ project.currency }}
            </div>
        </div>

        <suggested-calculation
            v-if="documents.length > 0"
            :project="project"
            :documents="documents"
        />
    </div>
</template>

<style lang="scss" scoped>
.ProjectCalculation {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__main {
        display: flex;
        align-items: center;
        gap: 4px;

        [data-id="hours"] {
            width: 75px;
        }

        [data-id="rate"] {
            width: 75px;
        }

        [data-id="discount"] {
            width: 80px;
        }
    }

    &__result {
        font-size: 16px;
    }
}
</style>
