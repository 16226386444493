<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useEmployeeStore } from "@/stores/employees";
import { Employee } from "@/types";
import InnForm from "@/components/ds/form/Form.vue";
import NavigateBack from "@/components/ds/navigate-back/NavigateBack.vue";
import { useDebounceFn } from "@vueuse/core";
import employeeFormConfig from "./employee-form-config";
import IconButton from "@/components/ds/icon-button/IconButton.vue";
import api from "@/api";
import InnButton from "@/components/ds/button/Button.vue";
import { useUiStore } from "@/stores/ui";
import ClientDetails from "@/components/pages/client/ClientDetails.vue";
import ClientSecondary from "@/components/pages/client/ClientSecondary.vue";
import Panel from "@/components/general/Panel.vue";
import ClientTools from "@/components/pages/client/ClientTools.vue";
import ItemPage from "@/components/general/ItemPage.vue";
import InnSection from "@/components/general/Section.vue";
import EmployeeMail from "@/components/pages/employee/EmployeeMail.vue";

const route = useRoute();
const router = useRouter();

const employeesStore = useEmployeeStore();
const uiStore = useUiStore();

const employee = ref<Employee | null>(null);
const watchActive = ref(false);

const atBack = () => {
    router.push({ name: "Employees" });
};

const deleteEmployee = () => {
    if (employee.value) {
        api.delete(employee.value, "users").then(() => {
            if (employee.value) {
                employeesStore.delete(employee.value);
            }
            router.push({ name: "Employees" });
            uiStore.message("Werknemer verwijderd...");
        });
    }
};

const debouncedFn = useDebounceFn(() => {
    if (employee.value) {
        api.update(employee.value, "users").then(() => {
            uiStore.message("Wijzigingen opgeslagen...");
        });
    }
}, 1000);

watch(
    () => employee.value,
    () => {
        if (watchActive.value) {
            debouncedFn();
        }
    },
    { deep: true }
);

onMounted(() => {
    const id = Number(route.params.id);
    const e = employeesStore.list.find(
        (employee: Employee) => employee.id == id
    );
    if (e) {
        employee.value = e;
        setTimeout(() => {
            watchActive.value = true;
        });
    } else {
        router.push({ name: "Employees" });
    }
});
</script>

<template>
    <item-page v-if="employee">
        <template #header>
            <navigate-back @click="atBack">
                Terug naar werknemers
            </navigate-back>
        </template>

        <template #cols>
            <panel title="Details">
                <inn-form :config="employeeFormConfig" :value="employee" />
            </panel>

            <panel title="Mail">
                <employee-mail :employee="employee" />
            </panel>

            <panel title="Tools">
                <inn-section>
                    <v-dialog max-width="500">
                        <template v-slot:activator="{ props: activatorProps }">
                            <icon-button
                                v-bind="activatorProps"
                                icon-id="trash"
                                variant="danger"
                            >
                                Verwijder werknemer
                            </icon-button>
                        </template>

                        <template v-slot:default="{ isActive }">
                            <v-card style="padding: 12px">
                                <v-card-text>
                                    Wil je {{ employee.name }} verwijderen?
                                </v-card-text>

                                <v-card-actions>
                                    <inn-button
                                        @click="
                                            () => {
                                                isActive.value = false;
                                                deleteEmployee();
                                            }
                                        "
                                    >
                                        Verwijderen
                                    </inn-button>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                </inn-section>
            </panel>
        </template>
    </item-page>
</template>
